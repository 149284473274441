import { Controller } from "stimulus"
import { get } from "../lib/request"
import exec from "../lib/exec"
import GtmController from "./gtm_controller.js"
import { disableImgModal } from "../core"

export default class extends Controller {
  static targets = ["contents"]
  connect() {
    if (!this.hasContentsTarget) {
      return 0
    }
    this.element.addEventListener("scroll", this.onScroll.bind(this))

    this.baseUrl = window.location.toString()
    this.execScripts = this.data.get("exec-scripts")
    this.currentPage = 1
    this.finished = false
  }

  async onScroll(event) {
    if (!this.hasContentsTarget) {
      return 0
    }

    let scroll = event.target.scrollTop
    const div = event.target
    const scrollTop = div.scrollTop
    const scrollHeight = div.scrollHeight
    const offsetHeight = div.offsetHeight
    const scrollPercentage = (scrollTop / (scrollHeight - offsetHeight)) * 100
    if (scrollPercentage >= 90) {
      if (this.finished || this.loading || scroll < document.body.offsetHeight - 2 * window.innerHeight) {
        return
      }

      this.loading = true
      this.currentPage += 1
      this.element.classList.add("is-loading")

      try {
        // request next page
        let url = new URL(this.baseUrl)
        url.searchParams.set("page", this.currentPage)

        const response = await get(url)
        this.element.classList.remove("is-loading")

        if (response.status !== 200) {
          // no more results
          this.finished = true
          this.element.classList.add("is-finished-loading")
          return
        }

        // read response and append it to contents, marking script tags to be
        // reinjected manually (if needed/allowed)
        let html = await response.text()
        if (this.execScripts) {
          this.contentsTarget.insertAdjacentHTML("beforeend", exec.prepare(html))
          exec.replace(this.contentsTarget)
        } else {
          this.contentsTarget.insertAdjacentHTML("beforeend", html)
        }

        // send gtm event
        const controllerParam = this.element.getAttribute("data-gtm-controller-param")
        if (["news"].includes(controllerParam)) {
          GtmController.staticDisplayNewsPage()
        }

        // default img
        const defaultImg = document.querySelector("#defaultImg")
        const imgs = this.contentsTarget.querySelectorAll("img")
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i]
          img.onerror = () => {
            disableImgModal(img)
            img.src = defaultImg.getAttribute("src")
          }
        }

        if (this.replaceScripts) {
          Array.from(this.contentsTarget.querySelectorAll("script[data-replace='true']")).forEach(script => {
            const copy = document.createElement("script")
            copy.src = script.src
            script.parentElement.replaceChild(copy, script) // script.replaceWith(copy) isn't supported by IE11
          })
        }

        // send an event to tell other controllers that HTML was injected
        const evt = document.createEvent("CustomEvent")
        evt.initCustomEvent("scroller:load", true, true, null)
        this.element.dispatchEvent(evt)
      } finally {
        this.loading = false
      }
    }
  }
}
