import { Controller } from "stimulus"
import { post } from "../lib/request"

import readXlsxFile, { readSheetNames } from "read-excel-file"

export default class extends Controller {
  static targets = ["container", "form"]
  tabs = []

  connect() {}

  async setVersion(event) {
    const admin = window.ADMIN_PATH
    let formData = new FormData()
    formData.append("id", event.currentTarget.dataset.id)
    await post(admin + "/primonuments/set_version", formData).then(response => {
      console.log(response.status)
      location.href = admin + "/primonuments"
    })
  }

  async setNewVersion(event) {
    const admin = window.ADMIN_PATH
    this.tabs = []
    const fileUpload = document.getElementById("file").files[0]
    const filename = fileUpload.name.split(".")[0]
    if (fileUpload) {
      const reader = new FileReader()
      reader.readAsText(fileUpload, "UTF-8")
      reader.onload = async (evt) => {
        let sheetNames = await readSheetNames(fileUpload).then((sheetNames) => {
          return sheetNames
        })
        let dataJson = await this.createJson(fileUpload, sheetNames)
        const data = {
          collection: filename,
          tabs: dataJson
        }
        let formData = new FormData()
        formData.append("dataJson", JSON.stringify(data))
        formData.append("description", document.getElementById("description").value)
        await post(admin + "/primonuments/storeJson", formData).then(response => {
          if (response.status === 500) {
            alert("Format de données JSON n'est pas valide")
          } else {
            return response.json()
              .then(data => {
                if (data.statut === true) {
                  location.href = admin + "/primonuments?confirmation=true&id=" + data.last_inserted_id
                }
              })
          }
        })
      }
      reader.onerror = (evt) => {
        // document.getElementById("filr").innerHTML = "error reading file"
      }
    }
  }

  async createJson(fileUpload, sheetNames) {
    for (const sheetName of sheetNames) {
      let monumentsTab = []
      let nbGranites = 0 // nb de granites (prixVente)
      let nbTotalCasePrice = 0 // nb total de colonnes de "prix" (prixVente)
      let nbPrices = 0 // nb de prix dispo (prixVente)
      let colsPrices = [] // noms de colonne de prix disponibles (prixVente)

      let ordre = 0
      await readXlsxFile(fileUpload, { sheet: sheetName }).then((rows) => { // all rows
        rows.forEach((row, k) => {
          if (k === 0) { // première ligne
            row.forEach((col, j) => {
              if (col.includes("/")) {
                let arr = col.split("/")
                if (arr[2]) {
                  if (arr[2].toString().toLowerCase() === "granites") {
                    nbGranites++
                  }
                }
                if (arr[4]) { // => prixVente/0/prix/0/soubassement | prixVente/0/prix/0/tombale | prixVente/0/prix/0/poids | prixVente/0/prix/0/prix2 | ... | prixVente/0/prix/0/taille
                  if (colsPrices.indexOf(arr[4]) === -1) {
                    colsPrices.push(arr[4]) // récupérer tous les prix dispo (Pas de duplication)
                  }
                  nbTotalCasePrice++
                  if (arr[4].toString().toLowerCase() === "soubassement") {
                    nbPrices++
                  }
                }
              }
            })
          } else { // file content
            if (monumentsTab.length > 0) {
              let flag = false
              let el = monumentsTab.find((m) =>
                m.modele === row[0] &&
                m.Motif === row[3] &&
                m.defaut.granite === row[5]
              )
              if (el) {
                const dataGranite = this.getGranite(row, nbGranites)
                const dataPrix = this.getPrix(row, nbTotalCasePrice, nbPrices, nbGranites, colsPrices)
                el.prixVente.push({
                  granites: dataGranite,
                  prix: dataPrix
                })
                flag = true
              }
              if (!flag) {
                ordre++
                const dataGranite = this.getGranite(row, nbGranites)
                const dataPrix = this.getPrix(row, nbTotalCasePrice, nbPrices, nbGranites, colsPrices)
                let obj = {
                  modele: row[0],
                  designation: row[1],
                  dimension: row[2],
                  Motif: row[3],
                  Prix: {
                    Motif: this.emptyValue(row[4])
                  },
                  defaut: {
                    granite: row[5],
                    prix: row[6]
                  },
                  ordre: ordre,
                  images: [
                    row[8]
                  ],
                  prixVente: [
                    {
                      granites: dataGranite,
                      prix: dataPrix
                    }
                  ]
                }
                monumentsTab.push(obj)
              }
            } else {
              ordre++
              const dataGranite = this.getGranite(row, nbGranites)
              const dataPrix = this.getPrix(row, nbTotalCasePrice, nbPrices, nbGranites, colsPrices)

              let obj = {
                modele: row[0],
                designation: row[1],
                dimension: row[2],
                Motif: row[3],
                Prix: {
                  Motif: this.emptyValue(row[4])
                },
                defaut: {
                  granite: row[5],
                  prix: row[6]
                },
                ordre: ordre,
                images: [
                  row[8]
                ],
                prixVente: [
                  {
                    granites: dataGranite,
                    prix: dataPrix
                  }
                ]
              }
              monumentsTab.push(obj)
            }
          }
        })

        this.tabs.push({
          tab: sheetName.toLowerCase(),
          monuments: monumentsTab
        })
      })
    }
    return this.tabs
  }

  emptyValue(val) {
    if (val) {
      return val
    } else { return "" }
  }

  getGranite(row, nbGranites) {
    let data = []
    for (let i = 0; i < nbGranites; i++) {
      let index = 9 + i
      if (row[index]) {
        data.push(row[index])
      }
    }
    return data
  }

  getPrix(row, nbTotalCasePrice, nbPrices, nbGranites, colsPrices) {
    let nbElm = nbTotalCasePrice / nbPrices
    let data = []

    for (let i = 9 + nbGranites; i < nbTotalCasePrice + 9 + nbGranites; i = i + nbElm) {
      let index = i
      let obj = {}
      for (const colPrice of colsPrices) {
        let value = row[index] ? row[index] : ""
        obj[colPrice] = value
        index++
      }
      data.push(obj)
    }
    return data
  }

  async update(event) {
    const admin = window.ADMIN_PATH
    let formData = new FormData()
    formData.append("id", event.currentTarget.dataset.id)
    formData.append("dataJson", document.getElementById("json_content").value)
    await post(admin + "/primonuments/updateJson", formData).then(response => {
      if (response.status === 500) {
        alert("Format de données JSON n'est pas valide")
      } else {
        return response.json()
          .then(data => {
            console.log(data.statut)
            if (data.statut === true) {
              location.href = admin + "/primonuments"
            }
          })
      }
    })
  }
}
