import { Controller } from "stimulus"
import throttle from "lodash/throttle"

// polyfill required on anything but Gecko and Chromium:
import smoothscroll from "smoothscroll-polyfill"
smoothscroll.polyfill()

export default class extends Controller {
  static targets = ["icon", "message"];

  initialize() {
    this.setVisibility = throttle(this.setVisibility, 1000)
  }

  setVisibility(event) {
    if (window.scrollY > 10) {
      this.iconTarget.classList.add("is-visible")
      this.messageTarget.classList.add("is-visible")
    } else {
      this.iconTarget.classList.remove("is-visible")
      this.messageTarget.classList.remove("is-visible")
    }
  }

  scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" })
  }

  scrollToTopOrToggleFiltersOnMobile(event) {
    if (window.scrollY > 0) {
      return this.scrollToTop()
    }
    if (window.innerWidth <= 540) {
      const evt = document.createEvent("CustomEvent")
      evt.initCustomEvent("scrolltop-toggle", true, true, null)
      this.element.dispatchEvent(evt)
    }
  }
}
