import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["destination"]

  toggle(event) {
    const name = this.data.get("name")
    this.eachDestination(destination => destination.classList.toggle(name))
    event.preventDefault()
  }

  toggleV2(event) {
    event.preventDefault()
    const name = this.data.get("name")
    const grpElement = event.currentTarget.parentElement.parentElement
    const isSame = grpElement.classList.contains(name)
    if (isSame) {
      grpElement.classList.remove(name)
    } else {
      if (grpElement.classList.contains("sidebar-child")) {
        grpElement.classList.add(name)
      } else {
        this.remove(event)
        grpElement.classList.add(name)
      }
    }
  }

  add(event) {
    const name = this.data.get("name")
    this.eachDestination(destination => destination.classList.add(name))
    event.preventDefault()
  }

  remove(event) {
    const name = this.data.get("name")
    this.eachDestination(destination => destination.classList.remove(name))
    event.preventDefault()
  }

  eachDestination(callback) {
    if (this.hasDestinationTarget) {
      this.destinationTargets.forEach(callback)
    } else {
      callback(this.element)
    }
  }
}
