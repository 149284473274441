function prepare(html) {
  return html.replace(/<script([ >])/g, "<script data-replace=\"true\"$1")
}

function replace(element) {
  Array.from(element.querySelectorAll("script[data-replace='true']")).forEach(script => {
    const copy = document.createElement("script")
    if (script.src) {
      copy.src = script.src
    } else {
      copy.textContent = script.textContent
    }
    script.replaceWith(copy)
  })
}

export default { prepare, replace }
