import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "input"]

  connect() {
    this.template = document.importNode(this.containerTarget.children[0], true)
  }

  add(event) {
    const lastInput = this.inputTargets[this.inputTargets.length - 1]
    if (!lastInput || lastInput.value !== "") this.addInput()
  }

  addInput() {
    const node = document.importNode(this.template, true)

    const index = node.querySelector(".index")
    index.textContent = this.inputTargets.length + 1

    const input = node.querySelector("input")
    input.required = false
    input.value = null

    // remove label tag from node
    const label = node.querySelector("label")
    if (label) label.remove()

    this.containerTarget.appendChild(node)
  }
}
