import { Controller } from "stimulus"
import transliterate from "../transliterate"
import { get } from "../lib/request"
import GtmController from "./gtm_controller"

export default class extends Controller {
  static targets = ["modal", "contents", "search"];

  connect() {
    this.loaded = false
    this.debounce = null
  }

  search() {
    if (this.debounce) {
      clearTimeout(this.debounce)
    }
    this.debounce = setTimeout(() => this._search(), 100)
  }

  _search() {
    let search = transliterate(this.searchTarget.value.trim()).toLowerCase()

    let parent = ""
    let passSubParents = false
    let subParent = ""
    let passChildren = false
    Array.from(this.contentsTarget.querySelectorAll("li")).forEach(li => {
      if (search.length) {
        const level = li.getAttribute("data-level")
        if (level === "0") {
          if (transliterate(li.innerText.toLowerCase()).includes(search)) {
            li.style.display = ""
            passSubParents = true
          } else {
            parent = li
            li.style.display = "none"
            passSubParents = false
          }
        } else if (passSubParents) {
          li.style.display = ""
        } else if (level === "1") {
          if (transliterate(li.innerText.toLowerCase()).includes(search)) {
            li.style.display = ""
            parent.style.display = ""
            passChildren = true
          } else {
            subParent = li
            li.style.display = "none"
            passChildren = false
          }
        } else if (passChildren) {
          li.style.display = ""
        } else {
          if (transliterate(li.innerText.toLowerCase()).includes(search)) {
            li.style.display = ""
            if (parent && parent.style) {
              parent.style.display = ""
            }
            if (subParent && subParent.style) {
              subParent.style.display = ""
            }
          } else {
            li.style.display = "none"
          }
        }
      } else {
        li.style.display = ""
      }
      // if (search.length) {
      //   li.style.display = transliterate(li.innerText.toLowerCase()).includes(search) ? "" : "none"
      // } else {
      //   li.style.display = ""
      // }
    })
  }

  toggle() {
    let open = true
    if (this.modalTarget.classList.contains("is-open")) {
      open = false
      this.close()
    } else {
      this.open()
    }
    // Gtm
    GtmController.clickOnSelectOrganization(open)
    // END Gtm
  }

  open() {
    this._load()

    if (this.hasSearchTarget) {
      this.searchTarget.value = ""
      this._search()
    }

    this.modalTarget.classList.add("is-open")
  }

  close() {
    this.modalTarget.classList.remove("is-open")
  }

  closeOnEscape(event) {
    if (event.keyCode === 27) this.close()
  }

  preventClose(event) {
    event.stopPropagation()
  }

  _load() {
    if (this.loaded) return
    this.loaded = true

    get("/organizations")
      .then(response => response.text())
      .then(html => this.contentsTarget.innerHTML = html)
      .then(() => {
        // fix modal width, so filtering the list won't resize the modal:
        // only in extranet dashboard, not in the crm
        if (!this.element.classList.contains("crm-organizations")) this.contentsTarget.style.width = this.contentsTarget.offsetWidth + "px"
      })
  }
}
