import "trumbowyg"
import "trumbowyg/dist/plugins/table/trumbowyg.table.js" // table
import "trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily.js" // font family
import "trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize.js" // font size
import "trumbowyg/dist/plugins/colors/trumbowyg.colors.js" // colors
import "../plugins/explorer_editor" // File manage

export default class Editor {
  static container = ""

  constructor(container) {
    this.container = container
  }

  init() {
    let editorElement = $(this.container) // eslint-disable-line
    let trumbowyg = $.trumbowyg // eslint-disable-line
    jQuery.trumbowyg.langs.fr = { // eslint-disable-line no-undef
      viewHTML: "Voir le HTML",
      undo: "Annuler",
      redo: "Refaire",
      formatting: "Format",
      p: "Paragraphe",
      blockquote: "Citation",
      code: "Code",
      header: "Titre",
      bold: "Gras",
      italic: "Italique",
      strikethrough: "Barré",
      underline: "Souligné",
      strong: "Fort",
      em: "Emphase",
      del: "Supprimé",
      superscript: "Exposant",
      subscript: "Indice",
      unorderedList: "Liste à puces",
      orderedList: "Liste ordonnée",
      insertImage: "Ajouter une image",
      insertVideo: "Insérer une video",
      link: "Lien",
      createLink: "Insérer un lien",
      unlink: "Supprimer le lien",
      justifyLeft: "Aligner à gauche",
      justifyCenter: "Centrer",
      justifyRight: "Aligner à droite",
      justifyFull: "Justifier",
      horizontalRule: "Insérer un séparateur horizontal",
      removeformat: "Supprimer formatage",
      fullscreen: "Plein écran",
      close: "Fermer",
      submit: "Confirmer",
      reset: "Annuler",
      required: "Obligatoire",
      description: "Déscription",
      title: "Titre",
      text: "Texte",
      target: "Cible",
    }

    // Check if element exist
    if (editorElement.length === 0) {
      console.error("Element with Id " + this.container + " not found")
      return 0
    }

    // Check if attribute "icons-url" for loading icons
    let iconUrl = editorElement.attr("data-icons-url")
    if (iconUrl === undefined) {
      console.warn("Missing editor icons url on data attributes! Add | data: { icons_url: asset_url('icons.svg') } | ")
      trumbowyg.svgPath = false
    } else {
      trumbowyg.svgPath = iconUrl
    }

    // Init editor
    editorElement
      .trumbowyg({
        btns: [
          ["viewHTML"],
          ["undo", "redo"],
          ["formatting"],
          ["strong", "em", "del", "fontfamily", "fontsize"],
          ["foreColor", "backColor"],
          ["superscript", "subscript"],
          ["link"],
          ["insertImage"],
          ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
          ["unorderedList", "orderedList"],
          ["horizontalRule"],
          ["removeformat"],
          ["table"],
          ["fileManager"],
          ["fullscreen"]
        ],
        lang: "fr"
      })
  }
}
