import { Controller } from "stimulus"

export default class extends Controller {
  alert(e) {
    e.preventDefault()
    if (e.target.getAttribute("data-msg")) {
      alert(e.target.getAttribute("data-msg"))
    }
  }
}
