import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["switch"];

  connect() {
    this.toggleState()
  }

  toggleState(event) {
    this.switchTargets.forEach(sw => {
      const radio = sw.querySelector(".radio-toggle")
      if (radio) {
        [].forEach.call(sw.querySelectorAll("input:not(.radio-toggle),select,textarea"), input => {
          input.disabled = !radio.checked
        })
      }
    })
  }
}
