import { Controller } from "stimulus"

// A simple controller to sort a vertical list of items. Not perfect, and not
// tested against many browsers, but does the job.
//
// Something more serious would probably require more work, or merely wrap
// SortableJS.
//
// NOTE: works in Firefox as well as Chromium and Edge. Doesn't work in Safari.
export default class extends Controller {
  static targets = ["item", "handle"]

  start(event) {
    // TODO: prevent dragstart unless event.explicitOriginalTarget is the handle
    //       or a child of the handle
    this.dragging = event.currentTarget
    this.previousIndex = this.itemTargets.indexOf(this.dragging)

    if (this.dragging) {
      event.dataTransfer.effectAllowed = "move"
      this.dragging.classList.add("is-dragging")

      // fake drag (required for chromium/edge)
      event.dataTransfer.setData("text/html", "fake")

      if (this.data.get("ghost") !== "true") {
        // create an invisible ghost object to avoid the transparent ghost feedback
        const canvas = document.createElement("canvas")
        canvas.width = canvas.height = 50
        canvas.getContext("2d")
        event.dataTransfer.setDragImage(canvas, 25, 25)
      }
    } else {
      event.preventDefault()
    }
  }

  move(event) {
    // check whether the element is droppable:
    let item = event.currentTarget

    const index = this.itemTargets.indexOf(item)
    if (!this.dragging || index === -1) return

    // accept the element as droppable:
    event.preventDefault()

    // dragging down? insert after current item, otherwise insert before
    if (this.previousIndex < index) item = this.itemTargets[index + 1]
    this.element.insertBefore(this.dragging, item)

    this.previousIndex = index
  }

  check(event) {
    if (this.dragging && this.itemTargets.indexOf(event.currentTarget) !== -1) {
      // accept the element as droppable
      event.preventDefault()
    }
  }

  stop(event) {
    if (!this.dragging) return

    this.dragging.classList.remove("is-dragging")
    delete this.dragging
    event.preventDefault()
  }
}
