import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["iframe"]

  message(event) {
    // security check
    if (event.origin !== gon.config3d.origin) return

    // handle action
    switch (event.data && event.data.action) {
      case "configuration.order":
        document.body.modalController.open(`/pro-space/configurations/${event.data.configuration.reference}/order/new?redirect=1`)
        break
      case "configuration.preorder":
        document.body.modalController.open(`/pro-space/configurations/${event.data.configuration.reference}/preorder/new?redirect=1`)
        break
      case "configuration.add_to_family_space":
        document.body.modalController.open(`/pro-space/configurations/${event.data.configuration.reference}/family_space/search?redirect=1`)
        break
    }

    // handle not authorized message
    if (event.data && event.data === "config3d_401") {
      // reload page
      window.location.reload()
    }
  }
}
