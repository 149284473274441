import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["target"];

  update(e) {
    if (e.target.checked) {
      this.targetTarget.style.display = "flex"
    } else {
      this.targetTarget.style.display = "none"
    }
  }
}
