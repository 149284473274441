import { Controller } from "stimulus"
import GtmController from "./gtm_controller.js"

export default class extends Controller {
  static targets = ["container", "controls"]

  // TODO: must be reviewed: why not assign it in `initialize` hook?
  isOpen = 0

  connect() {
    const location = window.location.href
    this.isOpen = 1
    document.getElementById("result")?.classList.add("showed")
    document.getElementById("collapseExample")?.classList.add("show")
    if (location.includes("granite=") || location.includes("color=") || location.includes("D=")) { // eslint-disable-line no-undef
      this.isOpen = 1
      document.getElementById("result")?.classList.add("showed")
    }
  }

  toggle(e) {
    if (!this.isOpen) {
      document.getElementById("collapseExample").classList.add("show")
      document.getElementById("result").classList.add("showed")
      this.isOpen = 1
    } else {
      document.getElementById("collapseExample").classList.remove("show")
      document.getElementById("result").classList.remove("showed")
      this.isOpen = 0
    }
    // GTM event
    GtmController.filterToggle(!!this.isOpen, e.currentTarget.getAttribute("data-gtm-page-param"))
    // End GTM
  }

  goTo(event) {
    const target = event.currentTarget
    const link = target.getAttribute("link")
    const type = target.getAttribute("type")
    const path = Alpine.store("gpg").initConfiguration(type, link)
    const url = new URL(window.location.href)
    const baseUrl = url.origin
    window.location.href = baseUrl + path
  }
}
