import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["select", "link"]

  connect() {
    this.selectTarget.addEventListener("change", this.updateLink.bind(this))

    this.updateSelect()
  }

  updateLink() {
    let selectedCatalog = this.selectTarget.value
    let linkValue = this.linkTarget.value

    let [path, queryString] = linkValue.split("?")

    let params = queryString ? queryString.split("&") : []

    params = params.filter(param => !param.startsWith("catalog="))

    if (selectedCatalog) {
      params.push("catalog=" + selectedCatalog)
    }

    linkValue = path + (params.length ? "?" + params.join("&") : "")

    this.linkTarget.value = linkValue
  }

  updateSelect() {
    let linkValue = this.linkTarget.value
    let match = linkValue.match(/catalog=([^&]*)/)
    let selectedCatalog = match ? match[1] : null

    if (!selectedCatalog) {
      return
    }

    for (let i = 0; i < this.selectTarget.options.length; i++) {
      if (this.selectTarget.options[i].value === selectedCatalog) {
        this.selectTarget.selectedIndex = i
        break
      }
    }
  }
}
