import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["step", "modal", "link", "configurator"];

  connect() {
    this.stepTarget.disabled = true
  }

  toggleStepDisable(event) {
    const isChecked = event.target.checked
    this.stepTarget.disabled = !isChecked
    if (isChecked && !this.modalTarget.checked) {
      this.linkTarget.disabled = true
    } else if (isChecked && this.modalTarget.checked) {
      this.linkTarget.disabled = false
    } else {
      this.linkTarget.disabled = false
    }
  }

  toggleLinkDisable(event) {
    const isChecked = event.target.checked
    if (!isChecked && !this.configuratorTarget.checked) {
      this.linkTarget.disabled = false
    } else if (!isChecked && this.configuratorTarget.checked) {
      this.linkTarget.disabled = true
    } else {
      this.linkTarget.disabled = false
    }
  }

  setPathName(event) {
    try {
      const url = new URL(event.currentTarget.value)
      event.currentTarget.value = url.pathname
    } catch (error) {
    }
  }
}
