import { Controller } from "stimulus"
import { request, post } from "../lib/request"

const EXPLORER_ROOT_PATH = "/explorer/"
const SERVER_ERROR = "Une erreur cotée serveur, veuillez reessayer plus tard !"
const LOADER = "<div class='modal-loading'><img width='32' height='32' src='/assets/loading.gif'></div>"

export default class extends Controller {
  static targets = ["container", "filelist"]

  connect() {
    $(".multiple").select2() // eslint-disable-line no-undef
  }

  static async getExplorer(query = "selector=1") {
    this.filelistTarget.innerHTML = LOADER
    await request("GET", EXPLORER_ROOT_PATH + "?" + query)
      .then(response => {
        if (response.status < 400) {
          return response.text()
            .then(html => this.filelistTarget.innerHTML = html)
        }
      })
      .catch(() => {
        this.close()
      })
  }

  // override elements
  static overrideTargets() {
    this.filelistTarget = document.querySelector("[data-target='explorer.filelist']")
    this.containerTarget = document.querySelector("[data-target='explorer.container']")
  }

  async getFolderOfPath(event) {
    event.preventDefault()
    const target = event.currentTarget
    const actionUrl = target.href
    //
    await request("GET", actionUrl)
      .then(response => {
        if (response.status < 400) {
          return response.text()
            .then(html => {
              this.filelistTarget.innerHTML = html
              if (actionUrl.includes("?multiple=1")) {
                this.activeCheckedFiles()
              }
            })
        }
      })
      .catch((e) => {
        this.error("Veuillez rafraîchir la page et reessayer")
      })
  }

  async deleteFileOrFolder(event) {
    event.preventDefault()
    const target = event.currentTarget
    let fileOrFolderPath = target.getAttribute("path") || ""
    let msg = "Voulez vous supprimer " + fileOrFolderPath + " ?"
    // Open confirm
    let res = window.confirm(msg)
    if (res) {
      const actionUrl = target.href + this.isEditor()
      await request("DELETE", actionUrl)
        .then(response => {
          if (response.status < 400) {
            return response.text()
              .then(html => this.filelistTarget.innerHTML = html)
          }
        })
        .catch(() => {
          this.error()
        })
    }
  }

  async fileSelector(event) {
    event.preventDefault()
    const target = event.currentTarget
    const actionUrl = target.href

    await request("GET", actionUrl).then(response => {
      if (response.status < 400) {
        return response.text()
          .then(html => this.filelistTarget.innerHTML = html)
      }
    })
  }

  async submit(event) {
    event.preventDefault()
    const hiddenInput = document.getElementById(sessionStorage.getItem("input-hidden"))
    const filePathLabel = document.getElementById(sessionStorage.getItem("label"))
    const checkedFile = document.querySelector("input[name='selectedfile']:checked")
    const submitBtn = document.querySelector("input[name='commit']")
    submitBtn.setAttribute("disabled", true)
    let filePath = ""
    // Get File path from checked file
    if (checkedFile != null && checkedFile.value !== "") {
      filePath = checkedFile.value
    }

    // Clear label & value of input hidden
    filePathLabel.innerText = ""
    hiddenInput.value = ""

    if (filePathLabel == null || hiddenInput == null || filePath === "") {
      // close modal
      this.close()
      return 0
    }

    // Get hash of file
    let formData = new FormData()
    formData.append("image", filePath)
    // Send request
    await post(EXPLORER_ROOT_PATH, formData)
      .then(response => {
        if (response.status < 400) {
          return response.json()
            .then(data => {
              // Set value on input hidden & on label
              if (data.uuid && data.path) {
                hiddenInput.value = data.uuid
                if (filePathLabel.getAttribute("data-return") != null) {
                  filePathLabel.innerText = data.name
                } else {
                  filePathLabel.innerText = data.path
                }
                if (filePathLabel.getAttribute("data-deleteImage") != null) {
                  if (document.querySelector(".image-" + filePathLabel.getAttribute("data-deleteImage")) !== undefined) {
                    document.querySelector(".image-" + filePathLabel.getAttribute("data-deleteImage")).src = data.image
                  }
                  if (filePathLabel.getAttribute("data-checkbox") !== null) {
                    document.querySelector(".image-checkbox-" + filePathLabel.getAttribute("data-deleteImage")).checked = true
                  }
                }
              } else {
                filePathLabel.innerText = "Fichier ne peut pas être utilisé !"
              }
            })
        }
      })
      .catch(() => {
        filePathLabel.innerText = "Fichier ne peut pas être utilisé !"
      })
    submitBtn.removeAttribute("disabled")
    // close modal
    this.close()
  }

  async submitMultiple(event) {
    event.preventDefault()
    const hiddenInput = document.getElementById(sessionStorage.getItem("input-hidden"))
    const filePathLabel = document.getElementById(sessionStorage.getItem("label"))
    const checkedFiles = JSON.parse(sessionStorage.getItem("multiple"))
    const submitBtn = document.querySelector("input[name='commit']")
    submitBtn.setAttribute("disabled", true)

    // Clear label & value of input hidden
    filePathLabel.innerText = ""
    hiddenInput.value = ""

    if (filePathLabel == null || hiddenInput == null) {
      // close modal
      this.close()
      return 0
    }

    // Get hash of files
    let formData = new FormData()
    for (let i = 0; i < checkedFiles.length; i++) {
      formData.append("files[]", checkedFiles[i])
    }
    // Send request
    await post(EXPLORER_ROOT_PATH + "/multiple", formData)
      .then(response => {
        if (response.status < 400) {
          return response.json()
            .then(data => {
              // Set value on input hidden & on label
              if (data) {
                let uuids = []
                let paths = ""
                data.map(d => {
                  uuids.push(d.uuid)
                  paths += d.path + " <br>"
                  return 0
                })
                hiddenInput.value = uuids
                filePathLabel.innerHTML = paths
              } else {
                filePathLabel.innerText = "Fichier ne peut pas être utilisé !"
              }
            })
        }
      })
      .catch(() => {
        filePathLabel.innerText = "Fichier ne peut pas être utilisé !"
      })
    submitBtn.removeAttribute("disabled")
    // close modal
    this.close()
  }

  async ajouterFichier(event, confirmed = false) {
    event.preventDefault()
    const currentFolder = document.querySelector("input[name='currentfolder']")
    const fileUploaded = document.querySelector("input[name='image_file']")
    const submitBtn = document.querySelector("input[name='commit']")
    submitBtn.setAttribute("disabled", true)
    for (let i = 0; i < fileUploaded.files.length; i++) {
      if (currentFolder === null || fileUploaded.files[i] === undefined) {
        this.error("Veuillez rafraîchir la page et reessayer")
        return 0
      }

      // // Form Data
      let formData = new FormData()
      formData.append("folder_path", currentFolder.value)
      formData.append("file_uploaded", fileUploaded.files[i])
      if (confirmed) {
        formData.append("confirm", 1)
      }
      // // Send request
      let self = this
      await post(EXPLORER_ROOT_PATH + "file/" + this.isEditor(), formData)
        .then(response => {
          const contentType = response.headers.get("content-type")
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json()
              .then(data => {
                if (data.isExist === 1) {
                  if (confirm("Fichier existe déjà. Voulez-vous le remplacer ?") === true) {
                    self.ajouterFichier(event, true)
                  }
                }
              })
          } else {
            return response.text()
              .then(html => this.filelistTarget.innerHTML = html)
          }
        })
        .catch(() => {
          this.error()
        })
    }

    submitBtn.removeAttribute("disabled")
  }

  async ajouterDossier(event) {
    event.preventDefault()
    const currentFolder = document.querySelector("input[name='currentfolder']")
    const newFolder = document.querySelector("input[name='folder_name']")
    const submitBtn = document.querySelector("input[name='commit']")
    submitBtn.setAttribute("disabled", true)

    if (currentFolder == null || newFolder == null) {
      this.error("Veuillez rafraîchir la page et reessayer")
      return 0
    }

    // // Form Data
    let formData = new FormData()
    formData.append("folder_path", currentFolder.value)
    formData.append("new_folder", newFolder.value)
    // // Send request
    await post(EXPLORER_ROOT_PATH + "folder/" + this.isEditor(), formData)
      .then(response => {
        if (response.status < 400) {
          return response.text()
            .then(html => this.filelistTarget.innerHTML = html)
        }
      })
      .catch(() => {
        this.error()
      })
    submitBtn.removeAttribute("disabled")
  }

  error(msg = null) {
    const errorBlock = document.getElementById("errors-block")
    const submitBtn = document.querySelector("input[name='commit']")

    errorBlock.style.display = "block"
    errorBlock.innerText = msg || SERVER_ERROR
    if (submitBtn != null) {
      submitBtn.removeAttribute("disabled")
    }
  }

  addToEditorArea(event) {
    event.preventDefault()
    const taId = sessionStorage.getItem("taId")
    const textareaElement = document.getElementById(taId)
    const imgInsertBtn = textareaElement.parentNode.querySelector(".trumbowyg-insertImage-button")
    //
    const filesSelected = document.querySelector("input[name='selectedfile']:checked")
    if (filesSelected == null) {
      this.error("Veuiller selectionner un fichier !")
      return 0
    }
    // Add img to editor
    let clickEvent = document.createEvent("MouseEvents")
    clickEvent.initEvent("mousedown", true, true)
    imgInsertBtn.dispatchEvent(clickEvent)
    // Set value
    const urlInput = document.querySelector(".trumbowyg-modal.trumbowyg-fixed-top").querySelector("input[name='url']")
    urlInput.value = filesSelected.getAttribute("data-urlfile")
    this.close()
  }

  // Multi select ONSELECT
  fileChecked(event) {
    let filePath = event.target.value
    let checked = event.target.checked
    let fileSelected = JSON.parse(sessionStorage.getItem("multiple"))
    if (checked) {
      fileSelected.push(filePath)
    } else {
      fileSelected = fileSelected.filter(f => f !== filePath)
    }
    sessionStorage.setItem("multiple", JSON.stringify(fileSelected))
  }

  //
  activeCheckedFiles() {
    const fileSelected = JSON.parse(sessionStorage.getItem("multiple"))
    const files = document.querySelectorAll("input[name='selectedfile']")
    for (let i = 0; i < files.length; i++) {
      if (fileSelected.includes(files[i].value)) {
        files[i].setAttribute("checked", true)
      }
    }
  }

  isEditor() {
    if (sessionStorage.getItem("taId")) {
      return "?r=editor"
    } else if (window.location.search.includes("r=media")) {
      return "?r=media"
    } else if (sessionStorage.getItem("multiple")) {
      return "?r=multiple"
    }
    return ""
  }

  copyImgUrl(e) {
    const currentElement = e.target
    const imgUrl = currentElement.getAttribute("data-item-url-param")
    navigator.clipboard.writeText(imgUrl)
  }

  async replaceElements(e) {
    e.preventDefault()
    const toFolderElement = document.querySelector("[name='to_folder']:checked")
    const filesElement = document.querySelectorAll("[name='internfiles[]']:checked")
    if (filesElement.length === 0) {
      alert("Veuillez séléctionner les fichiers et les dossiers à déplacer !")
    } else if (!toFolderElement) {
      alert("Veuillez choisir un dossier !")
    } else {
      const mainForm = document.getElementById("expo_actions_form")
      let formData = new FormData(mainForm)
      formData.append("commit", "move")
      formData.append("to_folder", toFolderElement.value)
      // Send request
      await post(EXPLORER_ROOT_PATH + "multiple/action", formData)
        .then(response => response.json())
        .then(data => {
          window.location.replace(data.path)
        })
    }
  }

  checkAllFiles(e) {
    const checked = e.target.checked
    const filesElement = document.querySelectorAll("[name='internfiles[]']")
    for (let i = 0; i < filesElement.length; i++) {
      filesElement[i].checked = checked
    }
  }

  checkFile(e) {
    const checked = e.target.checked
    const checkAllInput = document.querySelector(".check-all")
    if (checked) {
      const filesElement = document.querySelectorAll("[name='internfiles[]']")
      const filesCheckedElement = document.querySelectorAll("[name='internfiles[]']:checked")
      if (filesElement.length === filesCheckedElement.length) {
        checkAllInput.checked = checked
      }
    } else if (checkAllInput.checked) {
      checkAllInput.checked = checked
    }
  }
  // ______________ MODAL ACTIONS ______________ //

  static async openFromEditor(id) {
    sessionStorage.setItem("taId", id)
    //
    this.overrideTargets()
    this.containerTarget.classList.add("is-open")
    //
    await this.getExplorer("editor=1")
  }

  open(event) {
    event.preventDefault()
    const currentElement = event.target
    this.containerTarget.classList.add("is-open")
    this.constructor.overrideTargets()
    if (currentElement.getAttribute("data-multiple") && currentElement.getAttribute("data-multiple") === "true") {
      sessionStorage.setItem("multiple", JSON.stringify([]))
      this.constructor.getExplorer("multiple=1")
    } else {
      this.constructor.getExplorer()
    }
    //
    if (currentElement.getAttribute("data-label")) {
      sessionStorage.setItem("label", currentElement.getAttribute("data-label"))
    }
    if (currentElement.getAttribute("data-input-hidden")) {
      sessionStorage.setItem("input-hidden", currentElement.getAttribute("data-input-hidden"))
    }
  }

  close(event) {
    if (event !== undefined) {
      event.preventDefault()
    }

    this.containerTarget.classList.add("close")
    this.containerTarget.classList.remove("is-open")
    this.containerTarget.classList.remove("close")

    if (sessionStorage.getItem("label")) {
      sessionStorage.removeItem("label")
    }
    if (sessionStorage.getItem("input-hidden")) {
      sessionStorage.removeItem("input-hidden")
    }
    if (sessionStorage.getItem("taId")) {
      sessionStorage.removeItem("taId")
    }
    if (sessionStorage.getItem("multiple")) {
      sessionStorage.removeItem("multiple")
    }
  }
}
