import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["lightbox", "item"];

  connect() {
    this.element.classList.add("without-price-animation")
    this._setPricesVisibility("buying")
    this._setPricesVisibility("selling")
    this.hideDuplicatedHeaders()
    setTimeout(() => this.element.classList.remove("without-price-animation"), 10)
  }

  // TODO: should call lightbox#openLink directly!
  zoomImage(event) {
    this._lightboxController()
      .open(event.currentTarget.dataset.fullsize || event.currentTarget.src)
  }

  fixHeader(event) {
    const header = document.querySelector("#header2023")
    header.style.zIndex = 0
  }

  openCarousel(event) {
    document.body.lightboxController.openCarousel(event.currentTarget.dataset.url)
  }

  togglePrices(event) {
    const type = event.currentTarget.dataset.priceType
    this._togglePricesVisibility(type)
    this._setPricesVisibility(type)
  }

  _togglePricesVisibility(type) {
    const key = `visible-${type}-prices`
    sessionStorage.setItem(key, sessionStorage.getItem(key) === "1" ? "0" : "1")
  }

  _setPricesVisibility(type) {
    if (sessionStorage.getItem(`visible-${type}-prices`) === "1") {
      this.element.classList.add(`with-${type}-prices`)
    } else {
      this.element.classList.remove(`with-${type}-prices`)
    }
  }

  // DEPRECATED
  _lightboxController() {
    return this.application
      .getControllerForElementAndIdentifier(this.lightboxTarget, "lightbox")
  }

  hideDuplicatedHeaders() {
    let previous

    this.itemTargets.forEach(item => {
      let title = item.querySelector("[data-list-duplicated-header-selector]").innerText.trim()
      if (title === previous) item.classList.add("item-header-hide")
      previous = title
    })
  }
}
