import { Controller } from "stimulus"

const MARGIN = 20
const ZOOM_MAX = 3
const ZOOM_MIN = 1

export default class extends Controller {
  static targets = ["contents", "loading", "loaded", "loadText"];

  initialize() {
    this._mousedown = (e) => this.mousedown(e)
    this._touchstart = (e) => this.touchstart(e)
    this._onmove = (e) => this.onmove(e)
    this._stop = (e) => this.stop(e)
    this._zoom = (e) => {
      e.preventDefault()
      this.zoom(e.deltaY * -0.01)
    }
  }

  connect() {
    this._currentIndex = null
    this._scrollX = null
    this._scrollY = null
    this._gestureHypo = null
    this._scale = ZOOM_MIN
    this._isZoomedIn = false
    this._originX = 0
    this._originY = 0

    this.margin = this.data.has("margin") ? parseInt(this.data.get("margin"), 10) : MARGIN

    this.resize()

    if (this.data.get("imagesUrl")) {
      this._imageCount = 0

      fetch(this.data.get("imagesUrl"), { method: "GET" })
        .then(response => response.json())
        .then(imageSet => this._load(imageSet))
        // .catch(error => this._error(error.message));
    } else {
      this._currentIndex = 0
      this._loadCounter = 0
      this.images = this.contentsTarget.children
      this._imageCount = this.images.length

      Array.from(this.images).forEach(img => {
        img.onload = () => this._updateLoader()

        // workaround to firefox (and older browsers) that load images inside
        // <template> elements:
        if (img.dataset.src) img.src = img.dataset.src
      })

      if (this._imageCount < 2) {
        this.element.classList.add("is-disabled")
      }
    }

    this.element.addEventListener("mousedown", this._mousedown)
    this.element.addEventListener("touchstart", this._touchstart)
    this.element.addEventListener("wheel", this._zoom)
  }

  disconnect() {
    this.element.removeEventListener("mousedown", this._mousedown)
    this.element.removeEventListener("touchstart", this._touchstart)
    this.element.removeEventListener("wheel", this._zoom)
    this._stop()
  }

  mousedown(event) {
    this._scrollX = event.clientX - this.element.offsetLeft
    this._scrollY = event.clientY - this.element.offsetTop

    document.body.addEventListener("mousemove", this._onmove)
    document.body.addEventListener("mouseup", this._stop)
    event.preventDefault()
  }

  touchstart(event) {
    if (!this._scrollX) {
      this._scrollX = event.touches[0].screenX - this.element.offsetLeft
      this._scrollY = event.touches[0].screenY - this.element.offsetLeft
      document.body.addEventListener("touchmove", this._onmove)
      document.body.addEventListener("touchcancel", this._stop)
      document.body.addEventListener("touchend", this._stop)
    }
    event.preventDefault()
  }

  onmove(event) {
    if (event.touches) {
      switch (event.touches.length) {
        case 1:
          if (this._isZoomedIn) {
            // touch: pan zoomed image
            this.pan(event)
          } else {
            // touch: spin 360 view
            this.spin(event)
          }
          break

        case 2:
          // touch: detect pinch/zoom gesture (2 fingers)
          this.ongesture(event)
          return
      }

      // reset (too many fingers)
      this._gestureHypo = null
      return
    }

    if (this._isZoomedIn) {
      // mouse: pan already zoomed image
      this.pan(event)
    } else {
      // mouse: spin 360 view
      this.element.classList.add("is-scrolling")
      this.spin(event)
    }
  }

  ongesture(event) {
    const currDiffX = event.touches[0].screenX - event.touches[1].screenX
    const currDiffY = event.touches[0].screenY - event.touches[1].screenY
    const currHypo = Math.hypot(currDiffX, currDiffY)

    if (this._gestureHypo !== null) {
      if (this._gestureHypo > currHypo) {
        // pinch
        this.zoom(-0.025)
      } else if (this._gestureHypo < currHypo) {
        // zoom
        this.zoom(+0.025)
      }
    }

    this._gestureHypo = currHypo
  }

  spin(event) {
    let x = (event.touches ? event.touches[0].screenX : event.clientX) - this.element.offsetLeft
    let threshold = this.element.offsetWidth / (this._imageCount * 1.2)
    let moved = this._scrollX - x

    if (Math.abs(moved) >= threshold) {
      this._scrollX = x
      this.show(this._currentIndex + (moved < 0 ? -1 : 1))
    }

    if (!event.touches) {
      // only prevent mousemove default behavior, preventing touchmove may
      // trigger a warning in the JS console
      event.preventDefault()
    }
  }

  stop(event) {
    this._scrollX = null
    this._scrollY = null
    this._gestureHypo = null
    this.element.classList.remove("is-scrolling")

    this._loadHiDPIImage()

    document.body.removeEventListener("mousemove", this._onmove)
    document.body.removeEventListener("mouseup", this._stop)

    document.body.removeEventListener("touchmove", this._onmove)
    document.body.removeEventListener("touchcancel", this._stop)
    document.body.removeEventListener("touchend", this._stop)

    if (event) {
      event.preventDefault()
    }
  }

  resize() {
    let w = 1920; let h = 1080; let ww; let wh; let ratio

    if (this.data.has("contained")) {
      ww = this.element.parentNode.offsetWidth
      wh = this.element.parentNode.offsetHeight
    } else {
      ww = window.innerWidth
      wh = window.innerHeight
    }
    ww -= 2 * this.margin
    wh -= 2 * this.margin
    ratio = Math.min(ww / w, wh / h)

    this.element.style.width = Math.round(w * ratio) + "px"
    this.element.style.height = Math.round(h * ratio) + "px"
  }

  show(index) {
    this.images[this._currentIndex].classList.remove("is-visible")

    // clamp to image set:
    if (index < 0) index = this._imageCount - 1
    if (index >= this._imageCount) index = 0

    this.images[index].classList.add("is-visible")
    this._currentIndex = index
  }

  zoom(delta) {
    this._scale += delta
    this._scale = Math.min(Math.max(ZOOM_MIN, this._scale), ZOOM_MAX)

    this._isZoomedIn = this._scale > ZOOM_MIN

    if (this._isZoomedIn) {
      this.element.classList.add("is-zoomed-in")
    } else {
      this.element.classList.remove("is-zoomed-in")
      this._originX = 0
      this._originY = 0
    }

    this._applyTransform()
  }

  pan(event) {
    if (!event.touches) {
      // only prevent mousemove default behavior, preventing touchmove may
      // trigger a warning in the JS console
      event.preventDefault()
    }

    let scrollX = (event.touches ? event.touches[0].screenX : event.clientX) - this.element.offsetLeft
    let scrollY = (event.touches ? event.touches[0].screenY : event.clientY) - this.element.offsetLeft
    let x = (this._scrollX - scrollX) / this._scale
    let y = (this._scrollY - scrollY) / this._scale

    this._scrollX = scrollX
    this._scrollY = scrollY

    let elementRect = this.element.getBoundingClientRect()
    let xMax = Math.max(0, elementRect.width / 2)
    let yMax = Math.max(0, elementRect.height / 2)

    this._originX = Math.min(Math.max(-xMax, this._originX - x), xMax)
    this._originY = Math.min(Math.max(-yMax, this._originY - y), yMax)

    this._applyTransform()
  }

  _applyTransform() {
    this.contentsTarget.style.transform = `scale(${this._scale}) translate(${Math.round(this._originX)}px, ${Math.round(this._originY)}px)`
  }

  // we always load the "small" image set (1080p) whatever the display
  // size/definition because the "large" image set is supposed to be 4K but
  // really is 1080p.
  _load(imageSet) {
    if (imageSet && imageSet.map) {
      this._loadCounter = 0
      this._imageCount = imageSet.length

      if (this._imageCount < 2) {
        this.element.classList.add("is-disabled")
      }

      this.images = imageSet.map(src => {
        if (typeof (src) === "string") {
          return this._render(src)
        } else {
          return this._render(src.small) // DEPRECATED: to support gpggranit-v2/config3d
        }
      })
    } else {
      throw new Error("Erreur de chargement")
    }
  }

  _render(src) {
    let img = document.createElement("img")
    img.className = "viewer3d-image"
    img.src = src

    if (this._currentIndex === null) {
      // show the first image ASAP:
      img.classList.add("is-visible")
      this._currentIndex = 0
    }
    img.onload = () => {
      this._updateLoader()
      img.onload = null
    }

    this.contentsTarget.appendChild(img)
    return img
  }

  _updateLoader() {
    this._loadCounter += 1
    this.loadTextTarget.innerText = Math.round(360 / this._imageCount * this._loadCounter) + "°"

    if (this._loadCounter === this._imageCount) {
      this.loadingTarget.classList.remove("is-visible")
      this.loadedTarget.classList.add("is-visible")
      this._loadHiDPIImage()
    }
  }

  // Replaces the standard definition image of the current index with its HiDPI
  // counterpart.
  //
  // This function is called when the whole set of standard images has been
  // loaded, then whenever we stop spinning.
  _loadHiDPIImage() {
    // wait until we loaded the whole set of images
    if (this._loadCounter !== this._imageCount) return

    let index = this._currentIndex
    let image = this.images[index]

    if (image._isHiDPIImage) return
    image._isHiDPIImage = true

    let url = this.data.get("fullImagesUrl")
    if (!url || !url.length) return
    let frameUrl = url + (url.includes("?") ? "&n=" : "?n=") + index

    fetch(frameUrl, { method: "GET" })
      .then(response => response.json())
      .then(urls => {
        if (urls && urls.length) image.src = urls[0]
      })
  }

  zoomIn() {
    this.zoom(0.5)
  }

  zoomOut() {
    this.zoom(-0.5)
  }
}
