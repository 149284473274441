import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggle", "label", "input", "item"]

  toggleAll() {
    const checked = this.toggleTarget.checked
    this.toggleTarget.classList.toggle("is-selected")
    this.inputTargets.forEach(checkbox => checkbox.checked = checked)
    this.itemTargets.forEach(item => this._set(item, checked))
  }

  toggleOne(event) {
    const index = this.inputTargets.indexOf(event.target)
    if (index < 0) return

    const item = this.itemTargets[index]
    if (item) this._set(item, event.target.checked)
  }

  // Removes the required attribute from all checkboxes in the set whenever at
  // least one checkbox is checked. Reinjects the required attribute when all
  // checkboxes are unchecked.
  requireAtLeastOneCheched(_) {
    if (this.inputTargets.some(checkbox => checkbox.checked)) {
      this.inputTargets.forEach(checkbox => checkbox.removeAttribute("required"))
    } else {
      this.inputTargets.forEach(checkbox => checkbox.setAttribute("required", "required"))
    }
  }

  _set(item, checked) {
    if (checked) {
      item.classList.add("is-selected")
    } else {
      item.classList.remove("is-selected")
    }
  }
}
