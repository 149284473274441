import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["menu"];

  toggle() {
    this.menuTarget.classList.toggle("is-visible")
  }

  close() {
    this.menuTarget.classList.remove("is-visible")
  }
}
