import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["wrapper", "content", "extra"]
  connect() {
    // Add an event listener to prevent clicks inside the modal content from closing the popup
    this.contentTarget.addEventListener("click", (event) => {
      event.stopPropagation()
    })

    // Close the popup if the user clicks outside the modal content
    document.addEventListener("click", this.hidePopup.bind(this))
  }

  showPopup(event) {
    let message
    let extraMsg
    if (event.type.startsWith("ajax:")) {
      if (event.detail[0].status < 400) {
        message = event.currentTarget.dataset.popupMessage
        this.wrapperTarget.classList.add("show-modal")
        this.contentTarget.innerHTML = message

        extraMsg = event.currentTarget.dataset.popupExtra
        if (extraMsg) {
          this.extraTarget.innerHTML = extraMsg
        }
      }
    }
  }

  hidePopup(event) {
    this._closePopup()
  }

  _closePopup() {
    this.wrapperTarget.classList.remove("show-modal")
    this.contentTarget.innerHTML = ""
  }
}
