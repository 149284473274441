import { Controller } from "stimulus"

import { request } from "../lib/request"
import introJs from "intro.js"

let flagCloseModal = false
let event = null
export default class extends Controller {
  static targets = ["tuto"];

  connect() {
    flagCloseModal = false
    // const intro = introJs()
    let modalId = this.data.get("modalId") // modal
    let pathname = window.location.pathname // page
    let type, value
    if (modalId) {
      type = "modal"
      value = modalId
    } else {
      type = "page"
      value = pathname
    }
    request("GET", `/intro?${type}=${value}`).then(response => {
      response.json().then(json => {
        if (!json.available) {
          if (type === "page") {
            if (this.tutoTarget) this.tutoTarget.style.display = "none"
          } else {
            if (document.getElementById("relaunch-intro")) document.getElementById("relaunch-intro").style.display = "none"
          }
        } else if (json.data.length > 0) {
          // this.setConfig(intro, json.data)
        }
      })
    }).catch((err) => {
      console.log(err)
    })
  }

  launch() {
    flagCloseModal = false
    let pathname = window.location.pathname
    const intro = introJs()
    const element = document.getElementById("catalogs")
    if (element) {
      element.classList.add("cat-introjs")
    }
    request("GET", `/intro?page=${pathname}&launch=true`).then(response => {
      response.json().then(json => {
        if (json.data.length > 0 && json.available) {
          this.setConfig(intro, json.data)
        }
      })
    })
  }

  launchInModal() {
    flagCloseModal = false

    const divElement = document.getElementById("modalInfo")
    const modalId = divElement.getAttribute("data-intro-modal-id")

    const intro = introJs()
    request("GET", `/intro?modal=${modalId}&launch=true`).then(response => {
      response.json().then(json => {
        if (json.data.length > 0 && json.available) {
          this.setConfig(intro, json.data)
        }
      })
    })
  }

  setConfig(intro, data) {
    intro.setOptions({
      tooltipClass: "customTooltip",
      showButtons: false,
      // hideNext: true,
      // exitOnOverlayClick: false,
      // exitOnEsc: false,
      showBullets: true,
      skipLabel: `
        <a href="#" class="close-intro" data-action="intro#closeIntro">
          <span>Quitter le didacticiel</span>
          <div class="close-intro-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5836 1.19444L1.19472 22.5833M22.5836 22.5833L1.19472 1.19444" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>`,
      doneLabel: false,
      keyboardNavigation: false,
      steps: data
    }).start()

    intro.onbeforeexit(() => {
      if (flagCloseModal === true) {
        event.stopPropagation()
      } else if (intro._currentStep !== intro._introItems.length - 1) { // check if the last step and go to next step
        intro.nextStep()
        return false
      }
      const element = document.getElementById("catalogs")
      if (element) {
        element.classList.remove("cat-introjs")
      }
    })
  }

  closeIntro = (e) => {
    flagCloseModal = true
    event = e
  }
}
