import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["exclusive", "related", "submit"]

  connect() {
    this.setExclusive()
    this.errors = new Set()
  }

  setExclusive() {
    const fields = Array.from(this.exclusiveTargets)
    let found = null

    fields.some(field => {
      const isBlank = ("" + field.value).match(/^\s*$/)
      return isBlank ? false : (found = field)
    })

    fields.forEach(field => {
      field.readOnly = found ? found !== field : false
    })
  }

  // Enable related input if the current input has a non blank value,
  // otherwise disables the related input.
  toggleRelated(event) {
    this._setRelated(event, !!("" + event.currentTarget.value).match(/^\s*$/))
  }

  // Enable related input if the current input has a value, otherwise
  // disables the related input.
  enableRelated(event) {
    this._setRelated(event, false)
  }

  // Disable related input if the current input has a value, otherwise
  // disables the related input.
  disableRelated(event) {
    this._setRelated(event, true)
  }

  _setRelated(event, value) {
    const related = document.getElementById(event.currentTarget.dataset.related)
    if (related) {
      related.disabled = value
    }
  }

  // Toggle the visibilily of a block, and optionaly enables or disables nested
  // form controls.
  toggleBlock(event) {
    this.setBlockVisibility(event)
  }

  // Hide the visibilily of a block, and optionaly disables nested form controls.
  hideBlock(event) {
    this.setBlockVisibility(event, false)
  }

  // Display a block, and optionaly enables nested form controls.
  showBlock(event) {
    this.setBlockVisibility(event, true)
  }

  setBlockVisibility(event, visible = null) {
    const target = event.currentTarget
    const data = target.dataset

    Array.from(document.getElementsByClassName(data.related)).forEach(related => {
      if (visible === true) {
        related.classList.remove("is-hidden")
      } else if (visible === false) {
        related.classList.add("is-hidden")
      } else {
        related.classList.toggle("is-hidden")
        visible = !related.classList.contains("is-hidden")
      }

      if (data.disableInputs === "true") {
        Array
          .from(related.querySelectorAll("input, select, textarea"))
          .forEach(input => { input.disabled = !visible })
      }
    })
  }

  validateFileSize(event) {
    const target = event.currentTarget
    const maximum = parseInt(target.dataset.maximum, 10)
    const isInvalid = Array.from(target.files).some(file => file.size >= maximum)
    this._setError(target, isInvalid)
  }

  _setError(target, isInvalid) {
    if (isInvalid) {
      target.parentNode.classList.add("is-invalid")
      this.errors.add(target.name)
    } else {
      target.parentNode.classList.remove("is-invalid")
      this.errors.delete(target.name)
    }
    this.submitTarget.disabled = this.errors.size > 0
  }
}
