import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "clear"];

  connect() {
    this._listener = () => this.setVisibility()
    this.inputTarget.addEventListener("keyup", this._listener, false)
    this.setVisibility()
  }

  disconnect() {
    this.inputTarget.removeEventListener("keyup", this._listener, false)
  }

  setVisibility() {
    if (this.inputTarget.value.trim() === "") {
      this._hide()
    } else {
      this._show()
    }
  }

  clear() {
    this._hide()
    this.inputTarget.value = ""

    // manually trigger onchange event on input, because it wasn't triggered
    // automatically by the manual value change:
    const evt = document.createEvent("CustomEvent")
    evt.initCustomEvent("change", true, true, null)
    this.inputTarget.dispatchEvent(evt)
  }

  _show() {
    this.inputTarget.classList.add("primary", "text-white")
    this.clearTarget.style.display = "block"
  }

  _hide() {
    this.inputTarget.classList.remove("primary", "text-white")
    this.clearTarget.style.display = "none"
  }
};
