import { Controller } from "stimulus"
import { post } from "../lib/request"

export default class extends Controller {
  connect() {}

  publish(event) {
    const elmCheckbox = document.getElementById(event.currentTarget.id)
    let formData = new FormData()
    formData.append("value", elmCheckbox.checked)
    formData.append("id", parseInt(event.currentTarget.id))
    post(window.ADMIN_PATH + "/onboarding/publish", formData)
  }
}
