import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $("#language_iso") // eslint-disable-line no-undef
      .on(
        "select2:select",
        function (e) {
          let optionElements = document.querySelectorAll("#language_iso option")
          for (let i = 0; i < optionElements.length; i++) {
            if (optionElements[i].value === e.target.value) {
              let lang = optionElements[i].getAttribute("data-langue")
              if (document.querySelector("#language_language")) {
                document.querySelector("#language_language").setAttribute("value", lang)
              }
              break
            }
          }
        }
      )
  }
}
