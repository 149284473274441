import { Controller } from "stimulus"

const EMAIL_REGEX = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i

export default class extends Controller {
  static targets = ["check"]

  validate(event) {
    if (EMAIL_REGEX.test(event.currentTarget.value)) {
      this.checkTarget.classList.add("valid")
    } else {
      this.checkTarget.classList.remove("valid")
    }
  }
}
