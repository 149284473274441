import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["url"]

  toggleWithQuote(event) {
    const url = new URL(this.urlTarget.textContent)
    if (event.currentTarget.checked) {
      url.search = "?with_quote=1"
    } else {
      url.search = ""
    }
    this.urlTarget.textContent = url.href
    this.urlTarget.href = url.href
  }

  copyToClipboard(event) {
    this._copyToClipboard(this.urlTarget.textContent)
    event.preventDefault()
  }

  async _copyToClipboard(text) {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text)
      } catch (_ex) {
        this._fallbackCopyToClipboard(text)
      }
    } else {
      this._fallbackCopyToClipboard(text)
    }
  }

  _fallbackCopyToClipboard(text) {
    const textArea = document.createElement("textarea")
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    document.execCommand("copy")
    document.body.removeChild(textArea)
  }
}
