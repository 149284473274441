import { Controller } from "stimulus"
import template from "../lib/template"
export default class extends Controller {
  static targets = ["template", "container", "message", "input", "remove", "selectedIcon", "selectedId", "submit"]
  validateMimeType(event) {
    if (!this.inputTarget.files.length) return false
    const mimetypes = this.inputTarget.accept.split(",").map(type => type.trim())
    const isInvalid = Array.from(this.inputTarget.files).some(file => mimetypes.indexOf(file.type) === -1)
    this.messageTarget.style.display = isInvalid ? "" : "none"
    return !isInvalid
  }

  // FIXME: the following is much too complex

  connect() {
    this.attachments = []
    this.selected = this.inputTarget.dataset.default === "true" ? "current" : null
    this.initialChildren = this.hasContainerTarget ? this.containerTarget.children.length : 0
  }

  add(event) {
    if (this.validateMimeType(event)) {
      const files = event.currentTarget.files
      if (event.currentTarget.className.includes("granite_picto")) {
        let imgwidth = 0
        let imgheight = 0
        const maxwidth = 50
        let _URL = window.URL || window.webkitURL
        let img = new Image()
        img.src = _URL.createObjectURL(files[0])
        img.onload = function () {
          imgwidth = this.width
          imgheight = this.height
        }
        setTimeout(() => {
          if (imgwidth <= maxwidth && imgheight <= maxwidth) {
            this.submitTarget.click()
            this.attachments = this.attachments.concat(Array.from(files))
            if (!this.selected) this.selectDefault()
            this.updateAttach()
            this._render()
          } else {
            this.messageTarget.style.display = ""
          }
        }, 200)
      } else {
        this.attachments = this.attachments.concat(Array.from(files))
        if (!this.selected) this.selectDefault()
        this.updateAttach()
        this._render()
      }
    }
  }

  closeMessage() {
    this.messageTarget.style.display = "none"
  }

  remove(event) {
    let index = event.currentTarget.dataset.index
    if (index[0] !== "s") {
      this.attachments.splice(index, 1)
      if (this.selected === index) this.selectDefault()
      if (this.selected > index && /^\d+$/.test(this.selected)) this.updateSelected((this.selected - 1).toString())
      this.updateAttach()
    } else {
      let childrens = this.containerTarget.children
      childrens.forEach((child) => {
        if (!child.dataset.index || child.dataset.index !== index) return
        let isFav = child.querySelector("a").classList.contains("active")
        this.containerTarget.removeChild(child)
        this.initialChildren = this.initialChildren - 1
        if (isFav) this.selectDefault()
      })
    }
    this._render()
    event.preventDefault()
  }

  setSelected(event) {
    let index = event.currentTarget.dataset.index
    for (let i = 0; i < this.selectedIconTargets.length; i++) {
      let target = this.selectedIconTargets[i]
      if (target.dataset.index !== index) {
        target.classList.remove("active")
      } else {
        target.classList.add("active")
      }
    }

    if (index[0] === "s") {
      index = event.currentTarget.parentElement.lastElementChild.value
    }
    this.updateSelected(index)
  }

  _render() {
    Array.from(this.containerTarget.querySelectorAll("li"))
      .forEach((li, index) => {
        if (index >= this.initialChildren) {
          li.remove()
        }
      })
    this.attachments.forEach((attachment, index) => {
      index = index.toString()
      this.containerTarget.appendChild(this._renderTemplate(index, attachment, this.selected === index))
    })
  }

  _renderTemplate(index, attachment, active) {
    const node = template.clone(this.templateTarget)
    const a = node.querySelectorAll("a")
    a[0].dataset.index = index
    a[1].dataset.index = index
    if (active) {
      a[0].classList.add("active")
    }
    const img = node.querySelector("img")
    if (img) {
      const reader = new FileReader()
      reader.onload = e => { img.src = e.target.result }
      reader.readAsDataURL(attachment)
    }
    return node
  }

  FileListItems(files) {
    let b = new ClipboardEvent("").clipboardData || new DataTransfer()
    for (let i = 0, len = files.length; i < len; i++) b.items.add(files[i])
    return b.files
  }

  updateAttach() {
    this.inputTarget.files = this.FileListItems(this.attachments)
  }

  updateSelected(index) {
    this.selected = index
    this.selectedIdTarget.value = index || ""
  }

  selectDefault() {
    if (this.initialChildren > 1) {
      let a = this.selectedIconTargets[0]
      a.classList.add("active")
      this.updateSelected(a.parentElement.lastElementChild.value)
      this.selected = "current"
    } else if (this.attachments.length > 0) {
      this.updateSelected("0")
    } else {
      this.updateSelected(null)
    }
  }
}
