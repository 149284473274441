// IE11 doesn't support HTMLTemplateElement. We use this abstraction to create a
// `content` fragment on demand.

function content(template) {
  if (template.content) {
    return template.content
  }
  let fragment = document.createDocumentFragment()
  let children = template.children

  for (let i = 0; i < children.length; i++) {
    fragment.appendChild(children[i])
  }

  return template.content = fragment
}

function clone(template) {
  return document.importNode(content(template), true)
}

export default { content, clone }
