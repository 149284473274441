import ExplorerController from "../controllers/explorer_controller"

(function ($) {
  "use strict"

  // Plugin default options
  var defaultOptions = {} // eslint-disable-line no-var

  // If the plugin is a button
  function buildButtonDef(trumbowyg) {
    trumbowyg.o.plugins.fileManager.hasIcon = false
    trumbowyg.o.plugins.fileManager.text = trumbowyg.lang.fileManager
    return {
      fn: function () {
        return ExplorerController.openFromEditor($(trumbowyg.$ta[0]).attr("id"))
      },
      hasIcon: false,
      text: trumbowyg.lang.fileManager
    }
  }

  $.extend(true, $.trumbowyg, {
    // Add some translations
    langs: {
      en: {
        fileManager: "Ajouter une image"
      },
      fr: {
        fileManager: "Ajouter une image"
      }
    },
    // Register plugin in Trumbowyg
    plugins: {
      fileManager: {
        // Code called by Trumbowyg core to register the plugin
        init: function (trumbowyg) {
          // Fill current Trumbowyg instance with the plugin default options
          trumbowyg.o.plugins.fileManager = $.extend(true, {},
            defaultOptions,
            trumbowyg.o.plugins.fileManager || {}
          )

          // If the plugin is a paste handler, register it
          trumbowyg.pasteHandlers.push(function (pasteEvent) {})

          // If the plugin is a button
          trumbowyg.addBtnDef("fileManager", buildButtonDef(trumbowyg))
        },
        // Return a list of button names which are active on current element
        tagHandler: function (element, trumbowyg) {
          return []
        },
        destroy: function (trumbowyg) {}
      }
    }
  })
})(jQuery) // eslint-disable-line no-undef
