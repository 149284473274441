import { Controller } from "stimulus"
import { request } from "../lib/request"

export default class extends Controller {
  static targets = ["container", "description", "target"];

  connect() {
    this.checkAllInit()
  }

  async getRolesByProfile(event) {
    const target = event.currentTarget
    const admin = window.ADMIN_PATH
    const actionGetRoles = admin + "/profiles/" + target.value + "/roles"
    const actionGetRoleInfo = admin + "/profiles/" + target.value + ".json"

    // GET ROLES (HTML block)
    await request("GET", actionGetRoles).then(response => {
      if (response.status < 400) {
        return response.text()
          .then(html => this.containerTarget.innerHTML = html)
      }
    })
    this.checkAllInit()

    // GET PROFILE (JSON)
    await request("GET", actionGetRoleInfo).then(response => {
      if (response.status < 400) {
        return response.json()
          .then(data => {
            let desc = "_"
            if (data.description && data.description !== "") {
              desc = data.description
            }
            this.descriptionTarget.innerText = "Description: " + desc.toString()
          })
      }
    })
  }

  checkAllR(event) {
    this.checkAll(event.target.checked, "check-r")
  }

  checkAllW(event) {
    this.checkAll(event.target.checked, "check-w")
  }

  checkAll(check, classToCheck) {
    let elements = document.getElementsByClassName(classToCheck)
    for (let i = 0; i < elements.length; i++) {
      elements[i].checked = check
    }
  }

  checkListner(event) {
    const ele = event.target
    if (ele.classList.contains("check-w")) {
      const checkWAllInput = document.getElementById("check-all-w")
      if (checkWAllInput) {
        if (this.isChecked(ele.checked, "check-w")) {
          checkWAllInput.checked = ele.checked
        } else if (!ele.checked) {
          checkWAllInput.checked = false
        }
      }
    } else if (ele.classList.contains("check-r")) {
      const checkRAllInput = document.getElementById("check-all-r")
      if (checkRAllInput) {
        if (this.isChecked(ele.checked, "check-r")) {
          checkRAllInput.checked = ele.checked
        } else if (!ele.checked) {
          checkRAllInput.checked = false
        }
      }
    }
  }

  checkAllInit() {
    const checkWAllInput = document.getElementById("check-all-w")
    if (checkWAllInput && this.isChecked(true, "check-w")) {
      checkWAllInput.checked = true
    }
    const checkRAllInput = document.getElementById("check-all-r")
    if (checkRAllInput && this.isChecked(true, "check-r")) {
      checkRAllInput.checked = true
    }
  }

  isChecked(check, checkCLass) {
    let elements = document.getElementsByClassName(checkCLass)
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].checked !== check) {
        return false
      }
    }
    return true
  }
}
