import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element", "magnifier"];

  connect() {
    const url = new URL(window.location)
    this._show(url.search !== "")
  }

  resize() {
    // const inner = this.elementTarget.firstElementChild
    // this.elementTarget.style.height = inner.offsetHeight + "px"
    this.magnifierTarget.classList.add("is-top")
  }

  toggle(event) {
    this.elementTarget.style.transition = "height 400ms ease"
    setTimeout(() => this._show(this.elementTarget.style.height === ""), 0)
  }

  _show(visible) {
    if (visible) {
      this.resize()
    } else {
      this.elementTarget.style.height = ""
      this.magnifierTarget.classList.remove("is-top")
    }
  }
}
