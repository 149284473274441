import { Controller } from "stimulus"
import { get, post } from "../lib/request"
import template from "../lib/template"

export default class extends Controller {
  static targets = [
    "contents",
    "nav",
    "shareForm",
    "linkForm",
    "view360Template",
    "actions",
    "downloadBtn",
    "printBtn",
    "modal"
  ];

  connect() {
    this.actionsTarget.style.display = "none"

    if (this.data.has("generating")) {
      window.setTimeout(() => {
        this._waitFor(this.data.get("generating"), this.data.get("method"))
      }, 2000)
    } else {
      const target = this.navTarget.querySelector("a.active")
      this._setImage(target.href)
      this._setButtons(target)
    }
  }

  openImage(event) {
    this._setImage(event.currentTarget.href)
    this._updateNav(event)
    event.preventDefault()
  }

  openDocument(event) {
    const timer = setTimeout(() => this.element.classList.add("is-loading"), 300)
    const req = fetch(event.currentTarget.href, { headers: { "x-requested-with": "XMLHttpRequest" } })

    this.contentsTarget.innerHTML = ""
    this.contentsTarget.classList.add("pdf-document")
    this.actionsTarget.style.display = "flex"

    req
      .then(response => response.text())
      .then(html => {
        clearTimeout(timer)
        this.element.classList.remove("is-loading")

        this.contentsTarget.innerHTML = html
      })

    this._updateNav(event, { isDocument: true })
    event.preventDefault()
  }

  openView360(event) {
    const element = template.clone(this.view360TemplateTarget)

    this.contentsTarget.innerHTML = ""
    this.contentsTarget.appendChild(element)

    this._updateNav(event)
    event.preventDefault()
  }

  openIframe(event) {
    const element = document.createElement("iframe")
    element.src = event.currentTarget.href

    this.contentsTarget.innerHTML = ""
    this.contentsTarget.appendChild(element)
    this.actionsTarget.style.display = "flex"

    this._updateNav(event)
    event.preventDefault()
  }

  openShareForm(event) {
    this._openForm(event, this.shareFormTarget)

    if (this.hasLinkFormTarget) {
      this.linkFormTarget.classList.remove("open")
    }
  }

  openLinkForm(event) {
    this._openForm(event, this.linkFormTarget)

    if (this.hasShareFormTarget) {
      this.shareFormTarget.classList.remove("open")
    }
  }

  openModal(event) {
    this.modalTarget
      .modalController
      .open(event.currentTarget.href, { controller: this })
    event.preventDefault()
  }

  postEvent(event) {
    if (event.type !== "ajax:complete") return
    const xhr = event.detail[0]

    // NOTE: should always be true (see ModalController)
    if (xhr.status !== 204 && xhr.status < 300) {
      this.modalTarget.modalController.setContents(xhr.response)
    }
  }

  print(event) {
    const w = screen.width * 0.8
    const h = screen.height * 0.75
    const x = screen.width / 2 - w / 2
    const y = screen.height / 2 - h / 2
    window.open(event.currentTarget.href, "Impression", `height=${h},width=${w},left=${x},top=${y}`)

    event.preventDefault()
  }

  _openForm(event, target) {
    this.element.classList.add("has-sidebar")
    target.classList.add("open")
    event.preventDefault()
  }

  closeForm(event, target) {
    this.element.classList.remove("has-sidebar")

    if (this.hasShareFormTarget) {
      this.shareFormTarget.classList.remove("open")
    }
    if (this.hasLinkFormTarget) {
      this.linkFormTarget.classList.remove("open")
    }
    event.preventDefault()
  }

  toggleNavigation(event) {
    this.element.classList.toggle("has-nav-open")
    event.preventDefault()
  }

  _setImage(url) {
    let img = document.createElement("img")
    img.src = url
    img.setAttribute("crossorigin", "anonymous")

    this.contentsTarget.innerHTML = ""
    this.contentsTarget.appendChild(img)
  }

  _updateNav(event, options = {}) {
    this._active(event)
    this._setButtons(event.currentTarget)

    if (options.isDocument) {
      this.contentsTarget.classList.add("pdf-document")
    } else {
      this.contentsTarget.classList.remove("pdf-document")
    }
  }

  _active(event) {
    for (let active of this.navTarget.getElementsByClassName("active")) {
      active.classList.remove("active")
    }
    event.currentTarget.classList.add("active")
  }

  _setButtons(target) {
    if (target.dataset.download) {
      this.actionsTarget.style.display = ""
      this.downloadBtnTarget.href = target.dataset.download
      this.printBtnTarget.href = target.dataset.print
    } else {
      this.actionsTarget.style.display = "none"
    }
  }

  _waitFor(path, method) {
    if (method === "POST") {
      post(path + "?check=" + Date.now())
        .then(response => response.text())
        .then(html => {
          if (this.element.parentElement !== null) {
            this.element.outerHTML = html
          }
        })
    } else {
      get(path + "?check=" + Date.now())
        .then(response => response.text())
        .then(html => {
          if (this.element.parentElement !== null) {
            this.element.outerHTML = html
          }
        })
    }
  }
}
