import { Controller } from "stimulus"
import { post } from "../lib/request"
import Editor from "../lib/editor"

export default class extends Controller {
  static targets = ["container", "form"];

  connect() {
    let editor = new Editor("#contenu_popin_texte")
    editor.init()
  }

  async setVersion(event) {
    event.preventDefault()
    const button = event.target
    const selectedVersion = document.getElementById("version")
    // const admin = event.currentTarget.getAttribute("admin")
    const urlAction = window.ADMIN_PATH + "/msgpopins/set-version"

    button.setAttribute("disbaled", true)
    if (!selectedVersion) {
      return 0
    }

    let formData = new FormData()
    formData.append("version", selectedVersion.value)
    // GET SELECTED VERSION (HTML block)
    await post(urlAction, formData).then(response => {
      if (response.status < 400) {
        return response.text()
          .then(html => {
            this.formTarget.innerHTML = html
            let editor = new Editor("#contenu_popin_texte")
            editor.init()
            button.removeAttribute("disbaled")
          })
      }
    })
  }

  publishItForAll(event) {
    const organisationsBlock = document.getElementById("block_contenu_popin_organisations")
    const organisationsInput = document.getElementById("contenu_popin_organisations")
    let checked = event.target.checked
    if (checked) {
      organisationsBlock.style.display = "none"
      organisationsInput.removeAttribute("required")
      organisationsInput.value = ""
    } else {
      organisationsBlock.style.display = "block"
      organisationsInput.setAttribute("required", true)
    }
  }
}
