/* eslint no-unused-expressions:0 no-sequences: 0 */

if (gon.google_analytics && gon.google_analytics.enabled && !window.ga) {
  (function (i, s, o, g, r, a, m) {
    i.GoogleAnalyticsObject = r
    i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments) }, i[r].l = 1 * new Date()
    a = s.createElement(o), m = s.getElementsByTagName(o)[0]
    a.async = 1
    a.src = g
    m.parentNode.insertBefore(a, m)
  })(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga")

  ga("create", gon.google_analytics.site_id, "auto")

  window.addEventListener("turbolinks:load", function (event) {
    ga("send", "pageview", event.data.url)
  })
}
