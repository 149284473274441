import { Controller } from "stimulus"
import { post } from "../lib/request"
import "../lib/select2"

export default class extends Controller {
  static targets = ["form"]

  connect() {
    this.initMultiSelect()
  }

  initMultiSelect() {
    $(".multi-select").select2() // eslint-disable-line no-undef
  }

  async fn(e) {
    e.preventDefault()
    // const admin = e.currentTarget.getAttribute("admin")
    const url = window.ADMIN_PATH + "/catalog/monushops/getConfigByType"
    let formData = new FormData()
    formData.append("type", e.target.value)
    // get customize form (HTML block)
    await post(url, formData).then(response => {
      if (response.status < 400) {
        return response.text()
          .then(html => {
            this.formTarget.innerHTML = html
            this.initMultiSelect()
          })
      }
    })
  }

  getClickedBtn(e) {
    if (e.target.tagName === "SPAN") {
      return e.target.parentElement
    }
    return e.target
  }

  getLastIndex() {
    const elements = document.querySelectorAll(".subconfig")
    const lastEle = elements[elements.length - 1]
    return lastEle.getAttribute("id")
  }

  async addRecette(e) {
    e.preventDefault()
    // const admin = e.currentTarget.getAttribute("admin")
    const url = window.ADMIN_PATH + "/catalog/monushops/getSubConfig"
    let formData = new FormData()
    formData.append("lastIndex", this.getLastIndex())
    await post(url, formData).then(response => {
      if (response.status < 400) {
        return response.text()
          .then(html => {
            this.formTarget.innerHTML += html
            // this.formTarget.appendChild(html)
          })
      }
    })
  }

  deleteRecette(e) {
    e.preventDefault()
    if (document.querySelectorAll(".subconfig").length > 1) {
      let element = this.getClickedBtn(e)
      let id = element.getAttribute("data-lineid")
      if (id) {
        let line = document.getElementById(id)
        if (line) {
          line.remove()
        }
      }
    }
  }
}
