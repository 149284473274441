import { Controller } from "stimulus"
import { request } from "../lib/request"
import Editor from "../lib/editor"

export default class extends Controller {
  static targets = ["container", "description", "target", "categories"];

  connect() {
    const isLarge = document.getElementsByClassName("is-large")
    if (!isLarge) {
      this.initEditor()
    } else {
      this.deleteEmptyParagraph()
    }
  }

  deleteEmptyParagraph() {
    let paragraphs = document.querySelectorAll("p.text-dark")
    paragraphs.forEach(p => {
      if (p.textContent === " ") {
        p.classList.add("hidden2")
      }
    })
  }

  initEditor() {
    if (document.getElementById("text")) {
      let editor = new Editor("#text")
      editor.init()
    }
  }

  openList() {
    let list = document.getElementsByClassName("select_int")
    list[0].style.height === "unset" ? (list[0].style.height = "0") : (list[0].style.height = "unset")
  }

  async getContentByType(event) {
    const target = event.currentTarget
    // const admin = target.getAttribute("admin")
    const actionGetContent = window.ADMIN_PATH + "/actualites/type/" + target.value
    // GET Form type (HTML block)
    await request("GET", actionGetContent).then(response => {
      if (response.status < 400) {
        return response.text()
          .then(html => {
            this.containerTarget.innerHTML = html
            if (target.value === "texte") {
              this.initEditor()
            }
          })
      }
    })
  }

  // TEXTE BLOC - check add image
  checkAddImage(event) {
    const bloc = document.getElementById("texte_add_image_bloc")
    const addVCheckbox = document.getElementById("add_video")
    if (event.target.checked) {
      bloc.style.display = "block"
      addVCheckbox.setAttribute("disabled", true)
    } else {
      bloc.style.display = "none"
      addVCheckbox.removeAttribute("disabled")
    }
  }

  // TEXTE BLOC - check add video
  checkAddVideo(event) {
    const bloc = document.getElementById("texte_add_video_bloc")
    const addICheckbox = document.getElementById("add_image")
    if (event.target.checked) {
      bloc.style.display = "block"
      addICheckbox.setAttribute("disabled", true)
      bloc.querySelector("[name='video_id']").setAttribute("required", true)
    } else {
      bloc.style.display = "none"
      addICheckbox.removeAttribute("disabled")
      bloc.querySelector("[name='video_id']").removeAttribute("required")
    }
  }
}
// window.onload = function () {
//   document.addEventListener("mouseup", function (event) {
//     let listContainer = document.getElementsByClassName("select_mate")
//     let clickedInside = listContainer[0].contains(event.target)
//     if (!clickedInside) {
//       let list = document.getElementsByClassName("select_int")
//       list[0].style.height === "unset" && (list[0].style.height = "0px")
//     }
//   })
// }
