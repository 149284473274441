import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal"];

  toggle(e) {
    if (this.modalTarget.classList.contains("is-open")) { this.close(e) } else { this.open(e) }
  }

  open(e) {
    e.stopPropagation()
    this.modalTarget.classList.add("is-open")
  }

  close() {
    this.modalTarget.classList.remove("is-open")
  }

  closeOnEscape(event) {
    if (event.keyCode === 27) this.close()
  }
}
