import { Controller } from "stimulus"

import { request } from "../lib/request"

export default class extends Controller {
  async updateHandle(event) {
    const checkboxValue = event.target.checked
    const id = event.target.dataset.id

    request("POST", window.ADMIN_PATH + `/sent_mails/${id}/handle?status=${checkboxValue}`).then(response => {
      response.json().then(json => {
        return json
      })
    })
  }

  openMail(event) {
    event.preventDefault()

    const contentToCopy = event.target.dataset.content
    const mailLink = event.currentTarget
    const mailHref = mailLink.getAttribute("href")

    navigator.clipboard.writeText(contentToCopy)
      .then(() => {
        window.open(mailHref)
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard:", error)
      })
  }
}
