/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// libraries
import * as Rails from "@rails/ujs"
import * as Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

// polyfills
import "@stimulus/polyfills"
import "core-js/web/url"
import "core-js/web/url-search-params"
import "core-js/features/promise"
import "whatwg-fetch"
import "../lib/polyfills"
import "../core"

// trackers
import "../sentry"
import "../trackers/google_analytics"

// stimulus
import "../controllers"

import "flag-icons"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
