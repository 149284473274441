import { Controller } from "stimulus"
import { get } from "../lib/request"

export default class extends Controller {
  static targets = ["content"]

  connect() {
    this.getDataLastConfig()
      .then(res => {
        Alpine.store("catalogs").lastConfiguration = res
      })
  }

  async getDataLastConfig() {
    const url = this.contentTarget.getAttribute("data-last-config-url")
    return await get(url).then(response => response.json())
  }

  goToLastConfig() {
    // Alpin variables
    const lastConfiguration = Alpine.store("catalogs").lastConfiguration
    // END Alpin variables
    window.location.href = `/config3d/configuration/${lastConfiguration.reference}`
  }
}
