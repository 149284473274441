import { Controller } from "stimulus"
import { request } from "../lib/request"
import "../lib/select2"

export default class extends Controller {
  static targets = ["customization", "tree"];

  initMultiSelect() {
    if ($("#filtered_monuments_ids_") && $("#hidden_monuments_ids_") && $("#hidden_granites_ids_")) { // eslint-disable-line no-undef
      $("#filtered_monuments_ids_").select2() // eslint-disable-line no-undef
      $("#hidden_monuments_ids_").select2() // eslint-disable-line no-undef
      $("#hidden_granites_ids_").select2() // eslint-disable-line no-undef
    }
    if ($("#organization_client_ids_")) { // eslint-disable-line no-undef
      $("#organization_client_ids_").select2() // eslint-disable-line no-undef
    }
    if ($(".multiple")) { // eslint-disable-line no-undef
      $(".multiple").select2() // eslint-disable-line no-undef
    }
  }

  async editForm(e) {
    e.preventDefault()
    const link = e.target.href + "/customization/edit"
    // get customize form (HTML block)
    await request("GET", link).then(response => {
      if (response.status < 400) {
        return response.text()
          .then(html => {
            this.customizationTarget.innerHTML = html
            this.initMultiSelect()
          })
      }
    })
  }

  async editTreeForm(e) {
    e.preventDefault()
    const link = e.target.href + "/children-tree/edit"
    // get tree form (HTML block)
    await request("GET", link).then(response => {
      if (response.status < 400) {
        return response.text()
          .then(html => {
            this.treeTarget.innerHTML = html
            this.initMultiSelect()
          })
      }
    })
  }
}
