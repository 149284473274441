import * as Sentry from "@sentry/browser"

if (gon.sentry && gon.sentry.enabled) {
  Sentry.init({
    dsn: gon.sentry.dsn,
    environment: gon.sentry.environment,
    release: gon.sentry.release,
  })

  if (gon.sentry.user) {
    Sentry.setUser(gon.sentry.user)
  }

  if (gon.sentry.client_id) {
    Sentry.setExtra("client_id", gon.sentry.client_id)
  }
}
