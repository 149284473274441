// A collection of polyfills for DOM features missing in legacy browsers. This
// is mostly to support Internet Explorer 11 (IE11), which is still widely
// deployed in Enterprises.
//
// Currently patches:
//
// - ChildNode.prototype.remove()
// - ChildNode.prototype.replaceWith()
// - CustomEvent constructor

/* eslint no-prototype-builtins:0 */

const CHILD_NODES = [
  Element.prototype,
  CharacterData.prototype,
  DocumentType.prototype,
]

// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
function remove() {
  if (this.parentNode !== null) {
    this.parentNode.removeChild(this)
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/replaceWith
function replaceWith() {
  let parent = this.parentNode
  if (!parent) return

  let i = arguments.length
  if (!i) parent.removeChild(this)

  while (i--) {
    let currentNode = arguments[i]
    if (typeof currentNode !== "object") {
      currentNode = this.ownerDocument.createTextNode(currentNode)
    } else if (currentNode.parentNode) {
      currentNode.parentNode.removeChild(currentNode)
    }
    if (!i) {
      parent.replaceChild(currentNode, this)
    } else {
      parent.insertBefore(currentNode, this.previousSibling)
    }
  }
}

CHILD_NODES.forEach(prototype => {
  if (!prototype.hasOwnProperty("remove")) {
    Object.defineProperty(prototype, "remove", {
      configurable: true,
      enumerable: true,
      writable: true,
      value: remove,
    })
  }

  if (!prototype.hasOwnProperty("replaceWith")) {
    Object.defineProperty(prototype, "replaceWith", {
      configurable: true,
      enumerable: true,
      writable: true,
      value: replaceWith,
    })
  }
})

if (typeof window.CustomEvent !== "function") {
  window.CustomEvent = function CustomEvent(event, params) {
    params = params || {
      bubbles: false,
      cancelable: false,
      detail: null,
    }
    let evt = document.createEvent("CustomEvent")
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }
}
