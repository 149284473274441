import { Controller } from "stimulus"
import Editor from "../lib/editor"

export default class extends Controller {
  static targets = ["lightbox"]
  isValid = false

  connect() {
    if (document.querySelector("#admin")) {
      const admin = document.querySelector("#admin").getAttribute("admin")
      window.ADMIN_PATH = admin
    }
    this.customizeTitleAndStyle()
    if (document.querySelector("#documentation")) {
      let editor = new Editor("#documentation")
      editor.init()
    }
  }

  customizeTitleAndStyle() {
    let list, index, element
    list = document.getElementsByClassName("info-cac")
    for (index = 0; index < list.length; ++index) {
      list[index].setAttribute("title", "Ce paramètre ne sera pas synchronisé vers le CAC, il peut être écrasé par une MAJ côté CAC")
      element = list[index].querySelector("input[type=text]")
      if (element) list[index].querySelector("input[type=text]").setAttribute("style", "background-color: cornsilk;")

      element = list[index].querySelector("input[type=number]")
      if (element) list[index].querySelector("input[type=number]").setAttribute("style", "background-color: cornsilk;")

      element = list[index].querySelector("input[type=checkbox]")
      if (element) list[index].querySelector("input[type=checkbox]").nextElementSibling.setAttribute("style", "background-color: cornsilk;")

      element = list[index].querySelector("textarea")
      if (element) list[index].querySelector("textarea").setAttribute("style", "background-color: cornsilk;")

      element = list[index].querySelector("select")
      if (element) list[index].querySelector("select").setAttribute("style", "background-color: cornsilk;")

      element = list[index].querySelector(".select2-selection")
      if (element) list[index].querySelector(".select2-selection").setAttribute("style", "background-color: cornsilk !important;")

      element = list[index].querySelector("a")
      if (element) list[index].querySelector("a").setAttribute("style", "background-color: cornsilk")
    }

    list = document.getElementsByClassName("read-only")
    for (index = 0; index < list.length; ++index) {
      element = list[index].querySelector("input[type=text]")
      if (element) list[index].querySelector("input[type=text]").readOnly = true

      element = list[index].querySelector("input[type=number]")
      if (element) list[index].querySelector("input[type=number]").readOnly = true

      element = list[index].querySelector("input[type=checkbox]")
      if (element) {
        list[index].querySelector("input[type=checkbox]").disabled = true
        if (list[index].querySelector("input[type=checkbox]").previousSibling.type === "hidden") {
          list[index].querySelector("input[type=checkbox]").previousSibling.disabled = true
        }
      }

      element = list[index].querySelector("textarea")
      if (element) list[index].querySelector("textarea").readOnly = true

      element = list[index].querySelector("select")
      if (element) list[index].querySelector("select").disabled = true

      element = list[index].querySelector(".select2-selection")
      if (element) list[index].querySelector(".select2-selection").disabled = true
    }
  }

  // hint() {}

  zoomImage(event) {
    this.application
      .getControllerForElementAndIdentifier(this.lightboxTarget, "lightbox")
      .open(event.currentTarget.src)
    event.preventDefault()
  }

  colorInputValue(event) {
    document.querySelector(".color-" + event.target.attributes["data-color"].nodeValue).value = event.target.value
    document.querySelector(".color-background-" + event.target.attributes["data-color"].nodeValue).style.backgroundColor = event.target.value
  }

  colorPickerTrigger(event) {
    document.querySelector(".color-picker-" + event.target.attributes["data-colorInput"].nodeValue).click()
  }

  themeBackgroundColor(event) {
    document.querySelector(".color-background-" + event.target.attributes["data-color"].nodeValue).style.backgroundColor = event.target.value
  }

  handleSelectContent(e) {
    e.target.select()
  }

  handleInputToSubmitForm(e) {
    let form = e.target.form
    form.submit()
  }

  // Admin - catalog/monuments
  toggleEditForm(e) {
    e.preventDefault()
    const keywordsEditElement = document.querySelector("#keywords-edit")
    const toggleParam = e.currentTarget.getAttribute("data-toggle-param")
    //
    const editBtnElement = document.querySelector("#edit-btn")
    if (toggleParam === "open") {
      // edit block
      keywordsEditElement.classList.add("show")
      // edit Link
      editBtnElement.classList.remove("d-block")
      editBtnElement.classList.add("d-none")
    } else if (toggleParam === "close") {
      // edit block
      keywordsEditElement.classList.remove("show")
      // edit Link
      editBtnElement.classList.remove("d-none")
      window.location.href = e.currentTarget.href
    }
  }

  // Check if file input is empty
  isFileInputEmpty(fileInput) {
    return !fileInput || !fileInput.files || fileInput.files.length === 0
  }

  submitFormWithFileInput(e) {
    if (this.isValid) {
      return
    }
    e.preventDefault()
    const inputId = e.currentTarget.getAttribute("data-input-id-param")
    let fileInput = document.getElementById(inputId)
    if (this.isFileInputEmpty(fileInput)) {
      this.isValid = false
      alert("Veuillez sélectionner un fichier avant de soumettre le formulaire.")
    } else {
      this.isValid = true
      e.currentTarget.click()
    }
  }
}
