// app/javascript/controllers/multiplicator_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["wrapper", "counter", "element"]

  update() {
    const counter = parseInt(this.counterTarget.value, 10)
    if (counter < this.elementTargets.length) {
      // delete
      for (let i = this.elementTargets.length; i > counter; i--) {
        this.elementTargets[i - 1].remove()
      }
    } else {
      // add
      for (let i = this.elementTargets.length; i < counter; i++) {
        let newElement = this.elementTargets[0].cloneNode(true)
        newElement = newElement.outerHTML
        newElement = newElement.replaceAll("[0]", `[${i}]`)
        newElement = newElement.replaceAll("_0_", `_${i}_`)
        this.wrapperTarget.insertAdjacentHTML("beforeend", newElement)
      }
    }
  }
}
