import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["image"]

  zoomIn(event) {
    const target = event.currentTarget
    this.imageTarget.style.transform = "scale(2)"
    target.addEventListener("mousemove", this._pan(target))
  }

  zoomOut(event) {
    const target = event.currentTarget
    this.imageTarget.style.transform = ""
    this.imageTarget.style.transformOrigin = ""
    target.removeEventListener("mousemove", this._pan(target))
  }

  _pan(target) {
    const rect = target.getBoundingClientRect()
    return (event) => {
      const x = (event.clientX - rect.left) / rect.width
      const y = (event.clientY - rect.top) / rect.height
      this.imageTarget.style.transformOrigin = `${x * 100}% ${y * 100}%`
    }
  }
}
