import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formContainer", "emailForm", "linkForm", "familySpaceForm", "createBtn", "searchInput", "emailInput", "saveContainer", "createForm", "createFamilySpaceForm"]

  connect() {
    this.counter = 1
    this.defaultFormContent = this.saveContainerTarget.innerHTML

    this._disableButton()
  }

  updateForm() {
    const selectedValue = this.getSelectedValue()
    this.toggleForm(selectedValue)
  }

  getSelectedValue() {
    const selectedRadio = this.element.querySelector("input[name=\"share\"]:checked")
    return selectedRadio ? selectedRadio.value : null
  }

  toggleForm(selectedValue) {
    this._hideForms()
    // Show the selected form
    if (selectedValue === "1") {
      this.emailFormTarget.style.display = "block"
    } else if (selectedValue === "2") {
      this.linkFormTarget.style.display = "block"
    } else if (selectedValue === "3") {
      this.familySpaceFormTarget.style.display = "block"
    }
  }

  handleSearch(event) {
    let xhr

    switch (event.type) {
      case "ajax:error":
      case "ajax:success":
      case "ajax:complete":
        xhr = event.detail[0]
        if (xhr.status === 404) {
          this._handleNotFound()
        } else if (xhr.status === 200) {
          this._handleSuccess(xhr.response)
        }
        break
      default:
        throw new Error(`ModalController#updateContents: unknown event type ${event.type}`)
    }
  }

  handleSuccess(event) {
    let xhr

    switch (event.type) {
      case "ajax:error":
      case "ajax:success":
      case "ajax:complete":
        xhr = event.detail[0]
        if (xhr.status === 200) {
          this._handleSuccess(xhr.response)
        }
        break
      default:
        throw new Error(`ModalController#updateContents: unknown event type ${event.type}`)
    }
  }

  createFamilySpace(event) {
    let xhr

    switch (event.type) {
      case "ajax:error":
      case "ajax:success":
      case "ajax:complete":
        xhr = event.detail[0]
        if (xhr.status === 200) {
          this._handleSuccess(xhr.response)
        } else {
          this._handleCreateError(xhr.response)
        }
        break
      default:
        throw new Error(`ModalController#updateContents: unknown event type ${event.type}`)
    }
  }

  _handleCreateError(response) {
    this.createFormTarget.innerHTML = response
  }

  showDefaultForm() {
    this.saveContainerTarget.innerHTML = this.defaultFormContent
  }

  _enableButton() {
    // enable the button
    this.createBtnTarget.disabled = false
    this.createBtnTarget.classList.remove("button-inactive")
    this.createBtnTarget.classList.add("button-primary")
  }

  _disableButton() {
    // disable the button
    this.createBtnTarget.disabled = true
    this.createBtnTarget.classList.remove("button-primary")
    this.createBtnTarget.classList.add("button-inactive")
  }

  _handleNotFound() {
    this._enableButton()

    // clear the search input
    const searchValue = this.searchInputTarget.value
    this.searchInputTarget.value = ""

    // set the email input value
    this.emailInputTarget.value = searchValue

    // add focus to the email input
    this.emailInputTarget.focus()

    // scroll to buttom of the formContainer
    this.formContainerTarget.scrollTop = this.formContainerTarget.scrollHeight
  }

  _handleSuccess(response) {
    // embed the form in the save container
    this.saveContainerTarget.innerHTML = response

    // scroll to top of the formContainer
    this.formContainerTarget.scrollTop = 0

    // clear createFamilySpaceForm inputs
    this.createFamilySpaceFormTarget.reset()

    // delete all error messages
    const errorMessages = this.createFamilySpaceFormTarget.querySelectorAll(".is-error")
    errorMessages.forEach((errorMessage) => {
      errorMessage.remove()
    })
  }

  _hideForms() {
    // Hide all forms
    this.emailFormTarget.style.display = "none"
    this.linkFormTarget.style.display = "none"
    this.familySpaceFormTarget.style.display = "none"
  }
}
