import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  pictos(event) {
    const name = event.currentTarget.name
    // const admin = event.currentTarget.getAttribute("admin")
    const granite = document.querySelector("input[name=granite_pic]").value
    let pictos = []
    document.querySelectorAll("input[name=" + name + "]").forEach(element => {
      if (element.checked) {
        pictos.push(element.value)
      }
    })

    Rails.ajax({
      type: "patch",
      url: window.ADMIN_PATH + "/catalog/granites/" + granite,
      data: "pictos=" + pictos.join(":")
    })
  }

  showValid(event) {
    this.hideValid(event)
    const target = event.currentTarget
    target.button.style.visibility = "visible"
    event.preventDefault()
  }

  hideValid(event) {
    document.getElementsByClassName("validgraniteBtn").forEach(element => {
      element.style.visibility = "hidden"
    })
    event.preventDefault()
  }

  stop(event) {
    event.stopPropagation()
  }

  openGranitLink(event) {
    const target = event.currentTarget
    window.location.href = "/family-catalog/catalog?preview=false&granite=" + target.dataset.granitid
  }

  move(event) {
    const row = event.currentTarget.parentElement.parentElement
    const admin = window.ADMIN_PATH
    const parentnode = row.parentNode
    const button = event.currentTarget.button.className
    if (button.includes("up")) {
      const id1 = row.dataset.id
      const id2 = row.previousElementSibling.dataset.id
      const index1 = row.rowIndex
      const index2 = row.previousElementSibling.rowIndex
      parentnode.insertBefore(row, row.previousElementSibling)
      Rails.ajax({
        type: "post",
        url: admin + "/granitecolorsmove",
        data: "id=" + id1 + "&second_id=" + id2 + "&mainIndex=" + index2 + "&secIndex=" + index1
      })
    } else {
      const id1 = row.dataset.id
      const id2 = row.nextElementSibling.dataset.id
      const index1 = row.rowIndex
      const index2 = row.nextElementSibling.rowIndex
      parentnode.insertBefore(row.nextElementSibling, row)
      Rails.ajax({
        type: "post",
        url: admin + "/granitecolorsmove",
        data: "id=" + id1 + "&second_id=" + id2 + "&mainIndex=" + index2 + "&secIndex=" + index1
      })
    }
  }
}
