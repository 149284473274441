import { Controller } from "stimulus"
import throttle from "lodash/throttle"

export default class extends Controller {
  static targets = ["image"]

  initialize() {
    this._update = throttle(() => this.update(), 50)
  }

  connect() {
    if (document.getElementsByClassName("catalogs").length > 0) {
      document.body.classList.add("hidden")
    }
    // don't bother with browsers that don't support lazy loading
    // only eager load images sooner than when they enter the screen
    //
    // TODO: consider using the Intersection Observer API, but that requires
    //       detecting changes to imageTargets to (un)observe targets to
    //       intersect with the current viewport!
    if ("loading" in HTMLImageElement.prototype) {
      this._update()
      window.addEventListener("resize", this._update)
      window.addEventListener("orientationchange", this._update)
      window.addEventListener("scroll", this._update)
      const element = document.getElementsByClassName("new-catalog")[0]
      if (element) {
        element.addEventListener("resize", this._update)
        element.addEventListener("orientationchange", this._update)
      }
    }
  }

  disconnect() {
    window.removeEventListener("resize", this._update)
    window.removeEventListener("orientationchange", this._update)
    window.removeEventListener("scroll", this._update)
    const element = document.getElementsByClassName("new-catalog")[0]
    if (element) {
      element.removeEventListener("resize", this._update)
      element.removeEventListener("orientationchange", this._update)
    }
  }

  update() {
    this.imageTargets.forEach(img => {
      let monumentLink = img.closest(".monument-link")
      img.addEventListener("load", () => {
        if (monumentLink) monumentLink.classList.remove("disabled")
        img.loading = "lazy"
      })

      img.addEventListener("error", () => {
        try {
          if ((Alpine?.store("catalogs").loaded && img.src !== "") || ((Alpine?.store("stock").loaded) && img.src !== "")) {
            const defaultImg = document.querySelector("#defaultImg")
            if (Alpine?.store("stock").loaded) {
              Alpine?.store("stock").checkImage(img.dataset.ref)
              img.src = defaultImg.getAttribute("src")
              img.classList.remove("loading")
              return
            }
            img.src = defaultImg.getAttribute("src")
            img.loading = "eager"
            if (monumentLink) monumentLink.classList.add("disabled")
            if (img.classList.contains("monument-image")) {
              img.parentElement.style.marginLeft = 0
              img.parentElement.style.marginRight = 0
            }
          } else {
            img.loading = "lazy"
          }
        } catch (error) {}
      })
      if (img.loading === "eager" && img.src !== "") return
      img.classList.add("loading")
      if (monumentLink) monumentLink.classList.add("disabled")
      if (img.complete && img.naturalHeight !== 0) {
        // clearTimeout(timer)
        if (monumentLink) monumentLink.classList.remove("disabled")
        img.classList.remove("loading")
        img.loading = "eager"
      }

      img.onload = () => {
        // Set zoom effects on Page granit
        if (Alpine.store("granit").loaded && document.querySelector("#catalogs-granites").contains(img)) { // check if image exist on granits catalog
          const defaultImg = document.querySelector("#defaultImg")
          if (defaultImg.src !== img.src) {
            const blockId = img.getAttribute("data-block-id")
            const blockElement = blockId ? document.querySelector(`#${blockId}`) : false
            if (blockElement) {
              const actions = blockElement.getAttribute("data-action")
              blockElement.setAttribute("data-action", `mouseover->zoom-over#zoomIn mouseout->zoom-over#zoomOut ${actions}`)
            }
          }
        }
        // END Set zoom effects
        img.loading = "eager"
        if (monumentLink) monumentLink.classList.remove("disabled")
        if (img.complete && img.naturalHeight !== 0) {
          // clearTimeout(timer)
          img.classList.remove("loading")
          img.loading = "eager"
        }
      }
      img.onerror = () => {
        if ((Alpine.store("catalogs").loaded && img.src !== "" && img.loading !== "lazy") || (Alpine.store("granit").loaded && img.src !== "")) {
          const defaultImg = document.querySelector("#defaultImg")
          img.src = defaultImg.getAttribute("src")
          img.loading = "eager"
          if (monumentLink) monumentLink.classList.add("disabled")
          if (img.classList.contains("monument-image")) {
            img.parentElement.style.marginLeft = 0
            img.parentElement.style.marginRight = 0
          }
        } else {
          img.loading = "lazy"
        }
      }
      // img.loading = "eager"
    })
  }

  _offsetTop(element) {
    let offsetTop = 0
    while (element) {
      offsetTop += element.offsetTop
      element = element.offsetParent
    }
    return offsetTop
  }
}
