import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "report"]

  connect() {
    this.updateCounter()
  }

  updateCounter() {
    const currentLength = this.inputTarget.value.length
    const maxlength = parseInt(this.inputTarget.getAttribute("maxlength"), 10)
    this.reportTarget.innerText = (currentLength > 0) ? `${currentLength}/${maxlength}` : maxlength
  }
}
