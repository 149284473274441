import { Controller } from "stimulus"
import { get } from "../lib/request"

export default class extends Controller {
  static targets = ["image", "body"];

  connect() {
    document.body.lightboxController = this
  }

  disconnect() {
    this.close()
    document.body.lightboxController = null
  }

  open(imagePath) {
    this.element.classList.add("is-open")
    this.element.classList.add("is-loading")
    this.imageTarget.setAttribute("src", imagePath)
  }

  async openCarousel(url) {
    this.element.classList.add("is-open")
    this.element.classList.add("is-loading")
    const response = await get(url)
    const html = await response.text()
    this.bodyTarget.innerHTML = html
    this.loaded()
  }

  close() {
    this.element.classList.remove("is-open")
    this.element.classList.remove("is-loading")
    // this.imageTarget.removeAttribute("src")
    const header = document.querySelector("#header2023")
    if (header) {
      header.style.zIndex = 2048
    }
  }

  closeOnEscape(event) {
    if (event.keyCode === 27) {
      this.close()
    }
  }

  preventClose(event) {
    event.stopPropagation()
  }

  loaded(event) {
    this.element.classList.remove("is-loading")
  }
}
