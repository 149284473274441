import { Controller } from "stimulus"
import "../lib/select2"

export default class extends Controller {
  static targets = ["customization"];

  connect() {
    if ($("#organizations_")) { // eslint-disable-line no-undef
      $("#organizations_").select2() // eslint-disable-line no-undef
    }
  }

  editForm(e) {
    e.preventDefault()
    $(e.target).remove() // eslint-disable-line no-undef
    if ($("#form_edit_member_organization")) { // eslint-disable-line no-undef
      $("#form_edit_member_organization").css("display", "block") // eslint-disable-line no-undef
    }
  }

  editMemberDelegationForm(e) {
    e.preventDefault()
    $(e.target).remove() // eslint-disable-line no-undef
    if ($("#form_edit_member_delegation")) { // eslint-disable-line no-undef
      $("#form_edit_member_delegation").css("display", "block") // eslint-disable-line no-undef
    }
  }

  handleIsPublic(e) {
    const publicPriceBlockElement = document.querySelector("#publicPrice-block")
    if (publicPriceBlockElement) {
      const isChecked = e.target.checked
      if (isChecked) {
        publicPriceBlockElement.style.display = "table-row"
      } else {
        publicPriceBlockElement.style.display = "none"
      }
    }
  }
}
