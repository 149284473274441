const FRENCH_DIACRITICS = [
  /â/g, "a",
  /à/g, "a",
  /ä/g, "a",
  /é/g, "e",
  /è/g, "e",
  /ê/g, "e",
  /ë/g, "e",
  /ï/g, "i",
  /î/g, "i",
  /ô/g, "o",
  /ö/g, "o",
  /ù/g, "u",
  /û/g, "u",
  /ü/g, "u",
  /ÿ/g, "y",
  /ç/g, "c",
  /æ/g, "ae",
  /œ/g, "oe",
  /À/g, "A",
  /Â/g, "A",
  /Ä/g, "A",
  /Ç/g, "C",
  /É/g, "E",
  /È/g, "E",
  /Ê/g, "E",
  /Ë/g, "E",
  /Î/g, "I",
  /Ï/g, "I",
  /Ô/g, "O",
  /Ö/g, "O",
  /Ù/g, "U",
  /Û/g, "U",
  /Ü/g, "U",
  /Ÿ/g, "Y",
  /Æ/g, "AE",
  /Œ/g, "OE",
]

export default function transliterate(str, diacritics = FRENCH_DIACRITICS) {
  for (let i = 0, l = diacritics.length; i < l; i += 2) {
    str = str.replace(diacritics[i], diacritics[i + 1])
  }
  return str
}
