import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.title = this.element.getAttribute("title")
    this.element.removeAttribute("title")
  }

  disconnect() {
    this.hide()
  }

  show() {
    if (this.container) return

    // create and inject container
    this.container = document.createElement("div")
    this.container.classList.add("tooltip")
    this.container.classList.add(this.element.dataset.tooltipClass)

    let message = document.createElement("div")
    message.classList.add("tooltip-message")
    this.container.appendChild(message)

    this.arrow = document.createElement("div")
    this.arrow.classList.add("tooltip-arrow")
    this.container.appendChild(this.arrow)

    // set message
    message.innerText = this.title

    // determine size, position
    let anchor, x, y;
    [anchor, x, y] = this._getPosition()

    // add to DOM (hidden) then clamp to screen:
    anchor.appendChild(this.container)
    this._clampToVisibleScreen(x, y)

    // eventually make it visible:
    this.container.classList.add("is-visible")
  }

  _getPosition() {
    let x = 0
    let y = 0
    let node = this.element
    let anchor = document.body

    while (node) {
      // use specific position relative anchor (instead of body)
      if (node && node.classList.contains("tooltip-anchor")) {
        anchor = node
        break
      }

      // otherwise keep adding relative offsets
      x += node.offsetLeft
      y += node.offsetTop

      node = node.offsetParent
    }

    return [anchor, x, y]
  }

  _clampToVisibleScreen(x, y) {
    switch (this.element.dataset.tooltipPosition) {
      case "right":
        this._clampRight(x, y)
        break
      default:
        this._clampTop(x, y)
    }
  }

  _clampRight(x, y) {
    let maxX = window.innerWidth - 20
    let tx
    this.container.classList.add("is-right")

    // move tooltip to the right of the element, with some margin
    tx = x + this.element.offsetWidth + 10
    this.container.style.left = tx + "px"
    this.container.style.top = y + (Math.abs(this.container.offsetHeight - this.element.offsetHeight) / 2) + "px"

    // clamp width to fit into screen (especially on mobile)
    if (tx + this.container.offsetWidth >= maxX) {
      this.container.style.width = this.container.offsetWidth - x + "px"
    }

    // position arrow at center of element
    this.arrow.style.top = (this.container.offsetHeight / 2 - this.arrow.offsetHeight / 2) + "px"
  }

  _clampTop(x, y) {
    let w = this.container.offsetWidth
    let maxX = window.innerWidth - 20
    let tx
    this.container.classList.add("is-top")

    // move tooltip above element, with some margin
    tx = (x + w > maxX) ? maxX - w : x
    this.container.style.left = tx + "px"
    this.container.style.top = (y - this.container.offsetHeight - 10) + "px"

    // clamp width to fit into screen (especially on mobile)
    if (this.container.offsetWidth >= maxX) {
      tx = Math.max(tx, 0) + 10
      this.container.style.left = 10 + "px"
      this.container.style.width = maxX - 10 + "px"
    }

    // position arrow at center of element
    this.arrow.style.left = (Math.abs(tx - x) + this.element.offsetWidth / 2 - this.arrow.offsetWidth / 2) + "px"
  }

  hide() {
    if (this.container) {
      this.container.remove()
      delete this.container
    }
  }
};
