import { Controller } from "stimulus"

// Forwards a HTML fragment to ResponseController#open. This can be used when a
// modal response must replace the original object of a list-item but we still
// want to have a response dialog to confirm the action.
//
// The response is appended to the HTML response, which will connect this
// controller, that will then remove the element from the DOM and forward its
// contents to ResponseController.
//
// NOTE: when possible you should use ResponseController#open directly. Only use
// this when you need to render a template _and_ show the response dialog.
export default class extends Controller {
  connect() {
    let confirmation = this.data.get("confirmation") && this.data.get("confirmation") === "true"
    this.element.remove()
    this.application
      .getControllerForElementAndIdentifier(document.body, "response")
      .openContents(this.element.innerHTML, this.data.get("status"), confirmation)
  }
}
