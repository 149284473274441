import * as Rails from "@rails/ujs"

function csrfToken() {
  return document.head.querySelector("[name='csrf-token']").getAttribute("content")
}

function request(method, url, data = null, headers = {}) {
  let options = {
    method: method.toUpperCase(),
    credentials: "same-origin",
    headers: headers,
  }
  if (options.method !== "GET") {
    options.headers["x-csrf-token"] = csrfToken()
  }
  if (!options.headers["x-requested-with"]) {
    options.headers["x-requested-with"] = "XMLHttpRequest"
  }
  if (data) {
    options.body = data
  }

  return fetch(url, options).then(response => {
    if (response.status === 401) {
      throw new Error("Unauthorized")
    } else {
      return response
    }
  }).catch(error => {
    if (error.message === "Unauthorized") {
      const authUrl = gon.routes.auth
      // Redirect to the login page
      window.location.href = authUrl
    } else { throw error }
  })
}

function post(url, data = {}, headers = {}) {
  if (!(data instanceof FormData)) {
    data = JSON.stringify(data)
    headers["content-type"] = "application/json"
  }
  return request("POST", url, data, headers)
}

function get(url, headers = {}) {
  return request("GET", url, null, headers)
}

function head(url, headers = {}) {
  return request("HEAD", url, null, headers)
}

function ajax(form, data, oncomplete) {
  Rails.ajax({
    type: form.method,
    url: form.action,
    data: data,
    dataType: "script",
    beforeSend: (xhr, options) => {
      if (Rails.fire(form, "ajax:beforeSend", [xhr, options])) {
        return Rails.fire(form, "ajax:send", [xhr])
      } else {
        Rails.fire(form, "ajax:stopped")
        return false
      }
    },
    success: (...args) => Rails.fire(form, "ajax:success", args),
    error: (...args) => Rails.fire(form, "ajax:error", args),
    complete: (...args) => {
      oncomplete(...args)
      return Rails.fire(form, "ajax:complete", args)
    },
    crossDomain: false,
    withCredentials: false,
  })
}

export { post, get, head, request, ajax }
