import { Controller } from "stimulus"

// polyfill required on anything but Gecko and Chromium:
import smoothscroll from "smoothscroll-polyfill"
smoothscroll.polyfill()

// TODO: consider merging with scrolltop (?)
export default class extends Controller {
  intoView(event) {
    event.preventDefault()

    const uri = new URL(event.currentTarget.href)
    const element = document.querySelector(uri.hash)

    if (element) {
      // scroll the page to the element
      let top = element.offsetTop

      // account for the fixed header
      // top -= document.querySelector(".main").offsetTop

      // account for the sticky nav
      if (window.getComputedStyle(this.element).position === "sticky") {
        top -= this.element.offsetHeight
      }

      if (document.querySelector(".contact-main")) {
        document.querySelector(".contact-main").scroll({ top: top, left: 0, behavior: "smooth" })
      } else {
        window.scroll({ top: top, left: 0, behavior: "smooth" })
      }
    }
  }
}
