import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["menu", "contacts"];

  open() {
    this.menuTarget.classList.toggle("is-open")
    document.getElementById("header-nav-id").classList.add("header-nav-is-open")
  }

  openContact() {
    this.contactsTarget.classList.toggle("is-open")
    document.getElementById("header-nav-id").classList.add("header-nav-is-open")
  }

  close() {
    this.menuTarget.classList.remove("is-open")
    document.getElementById("header-nav-id").classList.remove("header-nav-is-open")
  }

  closeContact() {
    this.contactsTarget.classList.remove("is-open")
    document.getElementById("header-nav-id").classList.remove("header-nav-is-open")
  }

  closeOnEscape(event) {
    if (event.keyCode === 27) this.close()
  }
}
