import { Controller } from "stimulus"

export default class extends Controller {
  copy(event) {
    event.preventDefault()

    const contentToCopy = event.target.dataset.content
    const alertText = event.target.dataset.alert

    navigator.clipboard.writeText(contentToCopy)
      .then(() => {
        event.target.textContent = "Copié!"
        setTimeout(() => {
          event.target.textContent = "Copier"
        }, 2000)

        if (alertText) alert(alertText)
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard:", error)
      })
  }
}
