import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"];

  toggle(e) {
    if (this.contentTarget.classList.contains("is-open")) { this.close(e) } else { this.open(e) }
  }

  open(e) {
    e.stopPropagation()
    this.contentTarget.classList.add("is-open")
  }

  close() {
    this.contentTarget.classList.remove("is-open")
  }
}
