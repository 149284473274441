import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "contents"]

  open(event) {
    let xhr
    switch (event.type) {
      case "ajax:error":
        xhr = event.detail[2]
        this.openContents(xhr.response, "error")
        break
      case "ajax:success":
        xhr = event.detail[2]
        this.openContents(xhr.response, "success")
        break
      case "ajax:complete":
        xhr = event.detail[0]
        this.openContents(xhr.response, (xhr.status >= 300) ? "error" : "success")
        break
      default:
        throw new Error(`ResponseController#open: unknown event type ${event.type}`)
    }
  }

  openContents(html, status, isconfirmation = false) {
    this.contentsTarget.innerHTML = html
    this.contentsTarget.classList.remove("is-error")
    this.contentsTarget.classList.remove("is-success")
    if (status) this.contentsTarget.classList.add(`is-${status}`)
    this.containerTarget.classList.add("is-open")
    if (isconfirmation) this.containerTarget.classList.add("confirmation")
    if (this.contentsTarget.baseURI.includes("show_pictos_confirm")) {
      this.contentsTarget.classList.add("picto-confirm")
    }
    if (this.contentsTarget.baseURI.includes("show_color_confirm")) {
      this.contentsTarget.classList.add("color-granit-confirm")
    }
    if (this.contentsTarget.getElementsByClassName("response-header")[0].classList.contains("configuration")) {
      this.containerTarget.classList.add("configuration")
    }
  }

  close(event) {
    const isconfig = this.contentsTarget.getElementsByClassName("response-header")[0].classList.contains("configuration")
    if (document.getElementsByClassName("modal")[0].classList.contains("is-open") && isconfig) {
      document.getElementsByClassName("modal")[0].classList.remove("is-open")
    }
    this.containerTarget.classList.remove("is-open")
    this.contentsTarget.innerHTML = ""
    event.preventDefault()
  }
}
