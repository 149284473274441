import { Controller } from "stimulus"
import debounce from "lodash/debounce"
import { get } from "../lib/request"
import exec from "../lib/exec"

export default class extends Controller {
  static targets = ["contents"];

  initialize() {
    this.onScroll = debounce(this.onScroll, 100)
  }

  connect() {
    this.baseUrl = window.location.toString()
    this.execScripts = this.data.get("exec-scripts")
    this.currentPage = 1
    this.finished = false
  }

  async onScroll(event) {
    let scroll = window.scrollY || window.pageYOffset
    if (this.finished || this.loading || scroll < document.body.offsetHeight - 2 * window.innerHeight) {
      return
    }

    this.loading = true
    this.currentPage += 1
    this.element.classList.add("is-loading")

    try {
      // request next page
      let url = new URL(this.baseUrl)
      url.searchParams.set("page", this.currentPage)

      const response = await get(url)
      this.element.classList.remove("is-loading")

      if (response.status !== 200) {
        // no more results
        this.finished = true
        this.element.classList.add("is-finished-loading")
        return
      }

      // read response and append it to contents, marking script tags to be
      // reinjected manually (if needed/allowed)
      let html = await response.text()
      if (this.execScripts) {
        this.contentsTarget.insertAdjacentHTML("beforeend", exec.prepare(html))
        exec.replace(this.contentsTarget)
      } else {
        this.contentsTarget.insertAdjacentHTML("beforeend", html)
      }

      if (this.replaceScripts) {
        Array.from(this.contentsTarget.querySelectorAll("script[data-replace='true']")).forEach(script => {
          const copy = document.createElement("script")
          copy.src = script.src
          script.parentElement.replaceChild(copy, script) // script.replaceWith(copy) isn't supported by IE11
        })
      }

      // send an event to tell other controllers that HTML was injected
      const evt = document.createEvent("CustomEvent")
      evt.initCustomEvent("scroller:load", true, true, null)
      this.element.dispatchEvent(evt)
    } finally {
      this.loading = false
    }
  }
}
